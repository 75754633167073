angular.module("wundery.checkout").factory("countries", function($translate, translations) {
  return {
    all: function() {
      return _.map(translations[$translate.use()]["COUNTRIES"], function(title, value) {
        return {
          title: title,
          value: value
        };
      });
    }
  };
});
