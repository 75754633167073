angular.module("wundery.checkout").controller("OverviewCtrl", function($q, $log, $scope, $timeout, $translate, $state, $stateParams, $modal, $window, configuration, checkouts, helpers, validation, countries, loading, errors, payment_processors, integrations, $rootScope, errorCodes, amazonPayError, states) {
  var billingAddressCountryCode, currentPaymentMethod, currentPaymentProcessor, executeFinishRequest, getStoreDefaultBillingCountryCode, groupFieldsIntoRows, load, loadCheckout, loadTitleAndStates, loadTitleState, paymentMethodData, resetErrors, setupPaymentProcessors, shippingAddressCountryCode, showHints, storeAddress, update, validateCheckout;
  loadTitleState = function(country) {
    var title;
    switch (country) {
      case 'IT':
      case 'CA':
      case 'CN':
      case 'PH':
      case 'TR':
      case 'PK':
        return title = $translate.instant("PROVINCE");
      case 'RU':
      case 'PT':
      case 'PA':
      case 'NZ':
      case 'HK':
        return title = $translate.instant("REGION");
      default:
        return title = $translate.instant("STATE");
    }
  };
  loadTitleAndStates = function(checkout) {
    var countryBilling, countryShipping;
    countryBilling = _.result(checkout, 'billing_address');
    countryShipping = _.result(checkout, 'shipping_address');
    if (countryBilling && _.result(countryBilling, 'country_code')) {
      $scope.titleBilling = loadTitleState(countryBilling.country_code.toUpperCase());
      $scope.possible_states_billing = states.all(countryBilling.country_code.toUpperCase());
    } else {
      $scope.titleBilling = $translate.instant("STATE");
      $scope.possible_states_billing = [];
    }
    if (countryShipping && _.result(countryShipping, 'country_code')) {
      $scope.titleShipping = loadTitleState(countryShipping.country_code.toUpperCase());
      return $scope.possible_states_shipping = states.all(countryShipping.country_code.toUpperCase());
    } else {
      $scope.titleShipping = $translate.instant("STATE");
      return $scope.possible_states_shipping = [];
    }
  };
  loadCheckout = function() {
    loading.start("checkout");
    $scope.show_error_amazon_pay = $stateParams.show_error === "true";
    return checkouts.get({
      id: $stateParams.checkoutId
    }, function(checkout) {
      return $scope.checkout = checkout;
    }).$promise.then(function() {
      return integrations.handle({
        checkout: $scope.checkout,
        scope: "overview"
      });
    })["finally"](function() {
      return loading.finish("checkout");
    });
  };
  update = function(checkout, permitUpdate) {
    if (permitUpdate == null) {
      permitUpdate = true;
    }
    $log.info("Update checkout ...");
    loading.start("checkout:update");
    return checkouts.update(checkout, function(_checkout) {
      if (permitUpdate) {
        $scope.checkout = _checkout;
        return $log.debug("Updated checkout");
      }
    }).$promise["finally"](function() {
      return loading.finish("checkout:update");
    });
  };
  $scope.activateHints = function() {
    $scope.validate = true;
    return $scope.updateCheckout($scope.checkout).then(function(updatedCheckout) {
      return $scope.checkout = updatedCheckout;
    });
  };
  $scope.can_finish_checkout = function() {
    return $scope.checkout.can_finish;
  };
  showHints = function() {
    return $scope.validate = true;
  };
  storeAddress = function(checkout) {
    return _.result(checkout.store, "address");
  };
  shippingAddressCountryCode = function(checkout) {
    return _.result(checkout.shipping_address, "country_code");
  };
  executeFinishRequest = function(checkout, authorization) {
    var params;
    params = {
      payment_authorization: authorization,
      amazon_access_token: Cookies.get('amazonAccessToken')
    };
    return checkouts.finish({
      id: checkout.id
    }, params, function(response) {
      $log.debug("Checkout finished");
      if (response.payment_result.redirecting) {
        $log.debug("Redirect requested, redirecting ...");
        $window.location.href = response.payment_result.redirect_url;
      } else if (response.payment_result.requires_action) {
        $rootScope.stripeInstance.handleCardAction(response.payment_result.payment_intent_client_secret).then(function(result) {
          if (result.error) {
            $scope.errors = [
              {
                message: result.error.message
              }
            ];
            return $q.when(true);
          } else {
            authorization = {
              payment_intent_id: result.paymentIntent.id
            };
            return executeFinishRequest(checkout, authorization);
          }
        });
      } else {
        $log.info("Finished checkout");
        $state.go("checkout.confirmation", {
          checkoutId: checkout.id
        }).then(function() {
          return $timeout(function() {
            return $window.location.reload();
          });
        });
      }
      return $q.when(true);
    }).$promise["catch"](function(response) {
      var code, data, message, payment_result, translateMessage;
      $log.warn("Error during finish");
      data = _.result(response, "data");
      payment_result = _.result(data, "payment_result");
      code = _.result(payment_result, "code");
      message = _.result(payment_result, "message");
      translateMessage = code ? $translate.instant("errors." + code) : message;
      if (data.error === true) {
        $scope.errors = [
          {
            key: code,
            message: translateMessage,
            technical_message: message
          }
        ];
      }
      if (data.type === "inventory") {
        return $scope.errors = [
          {
            code: "inventory",
            message: $translate.instant("errors.inventory"),
            meta: data.meta
          }
        ];
      }
    });
  };
  validateCheckout = function(checkout) {
    return checkouts.validate({
      id: checkout.id
    }).$promise;
  };
  setupPaymentProcessors = function(checkout) {
    var processorNames;
    $log.debug("Setup payment processors");
    return processorNames = _.forEach(checkout.payment_methods, function(payment_method) {
      var name, processor, test_mode;
      processor = _.result(payment_method, "processor");
      test_mode = _.result(payment_method, "test_mode");
      name = _.result(processor, "name");
      $log.info("Setup processor '" + name + "'");
      return payment_processors.setup(name, payment_method.safe_settings, test_mode, checkout);
    });
  };
  resetErrors = function() {
    $scope.errors = [];
    return $q.when(true);
  };
  currentPaymentMethod = function(checkout) {
    return _.result(checkout, "payment_method");
  };
  paymentMethodData = function(payment_method) {
    return $scope.payment_method_data[payment_method.id];
  };
  billingAddressCountryCode = function(checkout) {
    return _.result(checkout.billing_address, "country_code");
  };
  getStoreDefaultBillingCountryCode = function(checkout) {
    var address;
    address = storeAddress(checkout);
    return _.result(address, "country_code");
  };
  currentPaymentProcessor = function(checkout) {
    return _.result(currentPaymentMethod(checkout), "processor");
  };
  groupFieldsIntoRows = function(fields) {
    var groupedByY, rows, sortedFields;
    rows = [];
    sortedFields = fields.sort(function(a, b) {
      if (a.y_position !== b.y_position) {
        return a.y_position - b.y_position;
      } else {
        return a.x_position - b.x_position;
      }
    });
    groupedByY = {};
    sortedFields.forEach(function(field) {
      var _name;
      if (groupedByY[_name = field.y_position] == null) {
        groupedByY[_name] = [];
      }
      return groupedByY[field.y_position].push(Object.assign({}, field, {
        gridWidth: field.width
      }));
    });
    Object.keys(groupedByY).sort(function(a, b) {
      return parseInt(a) - parseInt(b);
    }).forEach(function(key) {
      var row;
      row = groupedByY[key];
      row.forEach(function(field, index) {
        if (index === 0) {
          return field.offset = field.x_position > 0 ? field.x_position : 0;
        } else {
          return field.offset = 0;
        }
      });
      return rows.push(row);
    });
    return rows;
  };
  load = function() {
    $log.debug("Loading checkout overview");
    $scope.validate = false;
    amazonPayError.clearError();
    return loadCheckout().then(function() {
      $scope.payment_method_data = {};
      $scope.isEmail = validation.isEmail;
      $scope.isDevelopment = configuration.isDevelopment();
      $scope.newCouponCode = '';
      $scope.possible_countries = countries.all();
      $scope.shippable_countries = helpers.shippableCountries($scope.checkout);
      $scope.error = false;
      $scope.checkout_form_items = groupFieldsIntoRows($scope.checkout.checkout_form_items);
      $scope.checkAvailablePaymentMethods();
      setupPaymentProcessors($scope.checkout);
      return loadTitleAndStates($scope.checkout);
    }).$promise;
  };
  load();
  $scope.isCheckoutable = function(checkout) {
    return (_.result(checkout, "disabled")) === false;
  };
  $scope.hasAvailableShippingMethod = function(checkout) {
    return _.some(_.map(checkout.shipping_method_availabilities, function(shipping_method_availability) {
      return shipping_method_availability.available === true;
    }), function(value) {
      return value === true;
    });
  };
  $scope.hasAvailablePaymentMethod = function(checkout) {
    return _.some(_.map(checkout.payment_method_availabilities, function(payment_method_availability) {
      return payment_method_availability.available === true;
    }), function(value) {
      return value === true;
    });
  };
  $scope.applyCoupon = function(newCouponCode, checkout) {
    loading.start("apply_coupon");
    return update(checkout)["finally"](function() {
      return checkouts.apply_coupon({
        id: $stateParams.checkoutId,
        coupon_code: newCouponCode
      }, function(checkout) {
        $scope.checkout = checkout;
        return $scope.newCouponCode = '';
      }).$promise["catch"](function(error) {
        var msg;
        if (error.status === 404) {
          switch (error.data.code) {
            case errorCodes.coupon.invalidDiscount:
              msg = 'errors.apply_coupon_too_high';
              break;
            case errorCodes.coupon.invalidTimesUsed:
              msg = 'errors.exceed_usage_limitation_of_coupon';
              break;
            default:
              msg = 'errors.apply_coupon';
          }
          return alert($translate.instant(msg));
        }
      })["finally"](function() {
        return loading.finish("apply_coupon");
      });
    });
  };
  $scope.removeCoupon = function(newCouponCode) {
    loading.start("remove_coupon");
    return checkouts.remove_coupon({
      id: $stateParams.checkoutId
    }, function(checkout) {
      return $scope.checkout = checkout;
    }).$promise["finally"](function() {
      return loading.finish("remove_coupon");
    });
  };
  $scope.loadDummyBillingAddress = function(checkout) {
    $log.debug("Loading dummy billing address ...");
    checkout.billing_address = {
      first_name: "Jon",
      last_name: "Doe",
      street: "Doe Rd",
      street_no: "42",
      country_code: "DE",
      zip: "1337",
      city: "Doesen",
      email: "doe@example.com",
      company: "Doe Industries Ltd.",
      phone: "+49111111111111"
    };
    return update(checkout);
  };
  $scope.resetBillingAddress = function(checkout) {
    $log.debug("Resetting dummy billing address ...");
    checkout.billing_address = {
      first_name: "",
      last_name: "",
      street: "",
      street_no: "",
      country_code: "",
      zip: "",
      city: "",
      email: "",
      company: ""
    };
    return update(checkout);
  };
  $scope.dummySignin = function(checkout) {
    $log.debug("signing in ...");
    return $scope.signin(checkout, "dexter@example.com", "secret");
  };
  $scope.loadDummyShippingAddress = function(checkout) {
    $log.debug("Loading dummy shipping address ...");
    checkout.separate_shipping_address = true;
    checkout.shipping_address = {
      first_name: "Maria",
      last_name: "Doe",
      street: "Doesen Circle",
      street_no: "7",
      country_code: "DE",
      zip: "4042",
      city: "Doesen",
      email: "doe@example.com"
    };
    return update(checkout);
  };
  $scope.debugCheckout = function(checkout) {
    return checkouts.get({
      id: checkout.id
    }, function(_checkout) {
      var instance;
      return instance = $modal.open({
        template: '<div class="modal-body"><pre>{{ checkout | json }}</pre></div>',
        size: "lg",
        resolve: {
          checkout: function() {
            return _checkout;
          }
        },
        controller: function($scope, checkout) {
          return $scope.checkout = checkout;
        }
      });
    });
  };
  $scope.signout = function(checkout) {
    loading.start("signout");
    return checkouts.signout({
      id: checkout.id
    }, function(_checkout) {
      return $scope.checkout = _checkout;
    }).$promise["finally"](function() {
      $scope.email = null;
      $scope.password = null;
      return loading.finish("signout");
    });
  };
  $scope.signin = function(checkout, email, password) {
    $log.debug("Signing in '" + email + "' on checkout '" + checkout.id + "'");
    $scope.error = false;
    loading.start("signin");
    return checkouts.signin({
      id: checkout.id,
      email: email,
      password: password,
      checkout_page: true
    }, function(_checkout) {
      var close, messsage;
      $scope.checkout = _checkout;
      messsage = $translate.instant('CART_UPDATED');
      close = $translate.instant('common.close');
      return $modal.open({
        template: "<div class='modal-body'>" + messsage + "</div> <div class='modal-footer'> <button type='button' class='btn btn-secondary' ng-click='closeModal()'>" + close + "</button> </div>",
        controller: function($scope, $modalInstance) {
          return $scope.closeModal = function() {
            $modalInstance.close();
            return $state.go("checkout.overview", {
              checkoutId: checkout.id
            });
          };
        },
        size: "lg"
      });
    }).$promise["catch"](function() {
      return $scope.error = true;
    })["finally"](function() {
      return loading.finish("signin");
    });
  };
  $scope.updateCheckout = function(checkout) {
    return update(checkout);
  };
  $scope.billingAddressCountryCodeChanged = function(checkout) {
    checkout.billing_address.state_code = null;
    checkout.billing_state_code = null;
    return update(checkout);
  };
  $scope.shippingAddressCountryCodeChanged = function(checkout) {
    checkout.shipping_address.state_code = null;
    checkout.shipping_state_code = null;
    return update(checkout);
  };
  $scope.enableSeparateShippingAddress = function(checkout) {
    $scope.checkout.separate_shipping_address = true;
    $log.info("Separate shipping address requested. Trigger checkout update ...");
    return update(checkout);
  };
  $scope.disableSeparateShippingAddress = function(checkout) {
    $scope.checkout.separate_shipping_address = false;
    $log.info("Separate shipping address disabled. Trigger checkout update ...");
    return update(checkout);
  };
  $scope.isSeparateShippingAddressEnabled = function(checkout) {
    return $scope.checkout.separate_shipping_address === true;
  };
  $scope.shippingMethodContext = function(shipping_method, checkout) {
    return _.first(_.filter(checkout.shipping_method_availabilities, {
      shipping_method_id: shipping_method.id
    }));
  };
  $scope.isShippingMethodAvailable = function(shipping_method, checkout) {
    var context;
    context = $scope.shippingMethodContext(shipping_method, checkout);
    return context.available === true;
  };
  $scope.shippingMethodChanged = function(checkout) {
    $log.info("Shipping method changed to '" + checkout.shipping_method_id + "'");
    return update(checkout);
  };
  $scope.paymentMethodChanged = function(checkout) {
    $log.info("requested payment method change '" + checkout.payment_method_id + "'");
    if ($rootScope.stripeCard && $rootScope.stripeCard.cardElement) {
      $rootScope.stripeCard.cardElement.unmount();
    }
    return update(checkout).then(function(updatedCheckout) {
      $log.info("Payment method changed to '" + checkout.payment_method_id + "'");
      return payment_processors.paymentMethodUpdated(updatedCheckout);
    });
  };
  $scope.paymentMethodContext = function(payment_method, checkout) {
    return _.first(_.filter(checkout.payment_method_availabilities, {
      payment_method_id: payment_method.id
    }));
  };
  $scope.persistPassword = function(checkout) {
    return update(checkout);
  };
  $scope.createPasswordChanged = function(checkout) {
    if (!checkout.create_password) {
      $log.info("Password removal requested. Trigger update ...");
      return update({
        id: checkout.id,
        password: "",
        create_password: false
      });
    }
  };
  $scope.unsetPassword = function(checkout) {
    $log.info("Password reset requested. Trigger update ...");
    return update({
      id: checkout.id,
      password: ""
    });
  };
  $scope.isPaymentMethodAvailable = function(payment_method, checkout) {
    var context;
    context = $scope.paymentMethodContext(payment_method, checkout);
    return context.available === true;
  };
  $scope.isCurrentPaymentMethod = function(payment_method, checkout) {
    return (_.result(checkout, "payment_method_id")) === (_.result(payment_method, "id"));
  };
  $scope.hasPartial = function(payment_method) {
    return payment_processors.hasPartial(payment_method.processor.name);
  };
  $scope.partialPath = function(payment_method) {
    return payment_processors.partialPath(payment_method.processor.name);
  };
  $scope.showPaymentPayPalExpress = function(checkout) {
    if (!checkout) {
      return null;
    }
    return _.first(_.filter(checkout.payment_methods, {
      processor: {
        name: 'paypal_express'
      }
    }));
  };
  $scope.finish = function(checkout) {
    $log.debug("Finishing checkout ...");
    loading.start("checkout:finish");
    return resetErrors().then(function() {
      return update(checkout, false);
    }).then(function(checkout) {
      if (checkout.can_finish) {
        $log.debug("Checkout can finish ...");
        return validateCheckout(checkout).then(function() {
          var amount, currency_code, data, name, paymentMethod, processor, settings;
          $log.info("Checkout validation successful");
          paymentMethod = currentPaymentMethod(checkout);
          processor = currentPaymentProcessor(checkout);
          name = _.result(processor, "name");
          data = paymentMethodData(paymentMethod);
          settings = _.result(paymentMethod, "safe_settings");
          amount = _.result(checkout, "total_gross_int");
          currency_code = _.result(_.result(checkout, "currency"), "code");
          return payment_processors.validation(name, data).then(function() {
            $log.info("Processor validation successful");
            return payment_processors.authorization(name, data, settings, amount, currency_code, checkout).then(function(authorization) {
              $log.info("Succeeded authorization, finishing checkout...");
              return executeFinishRequest(checkout, authorization);
            })["catch"](function(errors) {
              if (errors === 'ABORT') {
                return;
              }
              $log.info("Failed authorization: " + (JSON.stringify(errors)));
              return $scope.errors = _.map(errors, function(error) {
                if (error.message) {
                  return {
                    message: error.message
                  };
                } else {
                  return {
                    key: error.key,
                    message: $translate.instant("errors." + error.key)
                  };
                }
              });
            });
          })["catch"](function(errors) {
            $scope.errors = _.map(errors, function(error) {
              if (error.message) {
                return {
                  message: error.message
                };
              } else {
                return {
                  key: error.key,
                  message: $translate.instant("errors." + error.key)
                };
              }
            });
            return $log.debug("Validation failed");
          });
        })["catch"](function(errors) {
          var data;
          data = _.result(errors, "data");
          if (data.type === "inventory") {
            return $scope.errors = [
              {
                code: "inventory",
                message: $translate.instant("errors.inventory"),
                meta: data.meta
              }
            ];
          }
        });
      } else {
        showHints();
        return $log.info("Checkout cannot finish, do nothing (Reason returned from API: '" + checkout.explain + "')");
      }
    })["finally"](function() {
      return loading.finish("checkout:finish");
    });
  };
  $scope.createCustomerChanged = function(checkout) {
    if (checkout.store.allows_returning_customers) {
      return update(checkout);
    }
  };
  $scope.skipValidateStreetNo = function(checkout, billing) {
    if (billing == null) {
      billing = true;
    }
    if (!checkout.processor_paypal_express) {
      if (billing) {
        return $scope.validate && !checkout.billing_address.street_no;
      } else {
        return $scope.validate && checkout.separate_shipping_address && !checkout.shipping_address.street_no;
      }
    }
  };
  $scope.isGermanCountry = function(country_code) {
    return country_code === 'DE' || country_code === 'AT';
  };
  return $scope.checkAvailablePaymentMethods = function() {
    var availablePaymentMethods, availableShippingMethods;
    availablePaymentMethods = $scope.checkout.payment_methods.filter((function(_this) {
      return function(method) {
        return $scope.isPaymentMethodAvailable(method, $scope.checkout);
      };
    })(this));
    availableShippingMethods = $scope.checkout.shipping_methods.filter((function(_this) {
      return function(method) {
        return $scope.isShippingMethodAvailable(method, $scope.checkout);
      };
    })(this));
    if (availablePaymentMethods.length === 1) {
      $scope.checkout.payment_method_id = availablePaymentMethods[0].id;
    }
    if (availableShippingMethods.length === 1) {
      $scope.checkout.shipping_method_id = availableShippingMethods[0].id;
    }
    if ($scope.checkout.payment_method_id || $scope.checkout.shipping_method_id) {
      return update($scope.checkout);
    }
  };
});
