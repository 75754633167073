angular

  .module "wundery.checkout"

  .factory "missingTranslationHandler", (
    $log
    $translate
  ) ->
    (key) ->
      # $log.warn "Missing translation detected: '#{key}'"

