angular.module("wundery.checkout").factory("loading", function($rootScope) {
  $rootScope._loading = {};
  return {
    start: function(identifier) {
      return $rootScope._loading[identifier] = true;
    },
    finish: function(identifier) {
      return $rootScope._loading[identifier] = false;
    },
    isLoading: function(identifier) {
      return _.result($rootScope._loading, identifier) === true;
    }
  };
});
