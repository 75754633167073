angular.module("wundery.checkout").controller("authenticatePaypalExpressCtrl", function($scope, $state, paymentProcessorPaypalExpressDefinition) {
  var initData;
  initData = function(checkout) {
    var payment_method;
    if (checkout) {
      payment_method = $scope.showPaymentPayPalExpress(checkout);
      return paymentProcessorPaypalExpressDefinition.setupButton(payment_method.safe_settings, checkout, $scope, $state, true);
    }
  };
  return initData($scope.checkout);
});
