angular.module("wundery.checkout").filter("truncate", function() {
  return function(text, length, end) {
    if (_.isNaN(length)) {
      length = 10;
    }
    if (_.isUndefined(end)) {
      end = '...';
    }
    if (_.size(text) <= length || _.size(text) - _.size(end) <= length) {
      return text;
    } else {
      return String(text).substring(0, length - _.size(end)) + end;
    }
  };
});
