angular.module("wundery.checkout").factory("dom_manipulator", function($log) {
  var integrations;
  return new (integrations = (function() {
    function integrations() {}

    integrations.prototype.append = function(options) {
      var found, id, insert, refresh, source, target, _id;
      target = _.get(options, "target");
      id = _.get(options, "id");
      source = _.get(options, "source");
      refresh = (_.get(options, "refresh", false)) === true;
      insert = function() {
        $log.debug("Appending script to '" + target + "'");
        return angular.element(target).append(source);
      };
      if (_.isString(id)) {
        _id = "#" + id;
        $log.debug("ID '" + _id + "' is given");
        if (refresh) {
          $log.debug("... and refresh requested. First remove, then insert");
          angular.element(_id).remove();
          return insert();
        } else {
          found = angular.element(_id);
          if ((_.get(found, "length")) === 0) {
            return insert();
          } else {
            return $log.debug("Element already exists, do not refresh");
          }
        }
      } else {
        throw new Error("An 'id' parameter must be provided");
      }
    };

    return integrations;

  })());
});
