var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

angular.module("wundery.checkout").factory("configuration", function($log, environment, environmentName, translations) {
  return {
    isDevelopment: function() {
      return environmentName === "development";
    },
    isProduction: function() {
      return environmentName === 'production';
    },
    read: function(key) {
      var result;
      result = _.result(environment, key);
      if (_.isUndefined(result)) {
        return $log.error("Cannot read '" + key + "' from envirnment");
      } else {
        return result;
      }
    },
    locale: function(locale) {
      if (__indexOf.call(_.keys(translations), locale) >= 0) {
        return locale;
      } else {
        return "en";
      }
    }
  };
});
