angular

  .module "wundery.checkout"

  .factory "loading", (
    $rootScope
  )->

    $rootScope._loading = {}

    start: (identifier)  ->
      $rootScope._loading[identifier] = true

    finish: (identifier) ->
      $rootScope._loading[identifier] = false

    isLoading: (identifier) ->
      _.result($rootScope._loading, identifier) == true

