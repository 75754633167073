angular

  .module "wundery.checkout"

  .factory "validation", ->

    isEmail: (string) ->
      re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      re.test string


    isCreditcard: (value) ->
      # accept only digits, dashes or spaces
      if /[^0-9-\s]+/.test(value)
        return false
      # The Luhn Algorithm. It's so pretty.
      nCheck = 0
      nDigit = 0
      bEven = false
      value = value.replace(/\D/g, '')
      n = value.length - 1
      while n >= 0
        cDigit = value.charAt(n)
        nDigit = parseInt(cDigit, 10)
        if bEven
          if (nDigit *= 2) > 9
            nDigit -= 9
        nCheck += nDigit
        bEven = !bEven
        n--
      nCheck % 10 == 0
