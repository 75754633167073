angular
  .module "wundery.checkout"
  .factory "paymentProcessorPaypalExpressDefinition", (
    $q
    $log
    $translate,
    checkouts
    loading
  ) ->
    partial: false
    setupButton: (settings, checkout, scope, state, reauth = false) ->
      client_id = _.result _.find(settings, key: "client_id"), "value"
      head = ( document.getElementsByTagName "head" )[0]
      script = document.createElement "script"
      script.type = "text/javascript"
      script.src =
        "https://www.paypal.com/sdk/js?client-id=#{client_id}&disable-funding=credit,card,sepa,giropay,sofort,p24,venmo,bancontact,eps,ideal,mybank&currency=#{checkout.currency.code}&commit=#{reauth}"
      script.onload = ->
        paypal.Buttons(
          style:
            color: if reauth then 'black' else 'gold'
            label: if reauth then 'buynow' else 'paypal'
          onInit:  (data, actions) ->
            if checkout.can_finish || state.current.name == 'checkout.cart'
              actions.enable()
            else
              actions.disable()

            inputs = document.querySelectorAll 'input'
            _.forEach inputs, (element) ->
              element.addEventListener 'change', -> 
                scope.activateHints().then ->
                  if scope.checkout.can_finish
                    actions.enable()
                  else
                    actions.disable()

          onClick: (data, actions) ->
            loading.start "checkout.paypal_express"

            if reauth
              scope.activateHints()

            checkouts
              .create_order id: checkout.id, init_action: 'validate', (response) ->
                console.log("passed the validation.")
              .$promise
              .catch (error) ->
                loading.finish "checkout.paypal_express"
                scope.error = error.data
                actions.reject()
          createOrder: (data, actions) ->
            paymentMethod = scope.showPaymentPayPalExpress(checkout)
            if reauth
              checkouts
                .update_pp_shipping_costs id: checkout.id, { payment_method_id: paymentMethod.id }
                .$promise
                .then (response) ->
                  if response.paypal_order_id
                    return response.paypal_order_id
            else
              checkouts
                .create_order id: checkout.id, {init_action: 'create', payment_method_id: paymentMethod.id}
                .$promise
                .then (response) ->
                  # Returns actually the order id
                  if response.order
                    return response.order
                  else
                    scope.error = response.error
                    loading.finish "checkout.paypal_express"
                .catch (error) ->
                  loading.finish "checkout.paypal_express"
                  scope.error = error.data
          onApprove: (data, actions) ->
            if reauth
              checkouts
                .approve_paypal_amount id: checkout.id
                .$promise
                .then (response) ->
                  checkouts
                    .finish id: checkout.id, {}, (response) ->
                      state
                        .go "checkout.confirmation", checkoutId: checkout.id
                        .then ->
                          $window.location.reload()

            else
              # TODO (dh) - here we update the shipping
              # Update shipping is a stupid name, because it updates the
              # checkout address based on paypal address information
              checkouts
                .approve_paypal_amount id: checkout.id
                .$promise
                .then (response) ->
                  checkouts
                    .update_shipping id: checkout.id
                    .$promise
                    .then (response) ->
                      loading.finish "checkout.paypal_express"
                      actions.redirect(checkout.overview_url)
          onCancel: (data, actions) ->
            loading.finish "checkout.paypal_express"
            return true if reauth
            if data.orderID
              checkouts
                .cancel_order id: checkout.id, (response) ->
                  console.log('cancel order')
          onError: (err) ->
            console.log("error buttons" + err)
            loading.finish "checkout.paypal_express"
        ).render('#paypal-button-container')
      document.body.appendChild script


  .controller "paymentProcessorPaypalExpressCtrl", (
    $scope
    $state
    paymentProcessorPaypalExpressDefinition
  ) ->
    initData = (checkout) ->
      if checkout
        payment_method = $scope.showPaymentPayPalExpress(checkout)
        paymentProcessorPaypalExpressDefinition.setupButton(payment_method.safe_settings, checkout, $scope, $state)
    initData($scope.checkout)
