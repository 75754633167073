var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

angular.module("wundery.checkout").controller("CartCtrl", function($q, $log, $scope, $state, $stateParams, checkout_items, countries, checkouts, helpers, loading, integrations, payment_processors) {
  var load, loadCheckout, loadShippingSuggestion;
  loadCheckout = function() {
    loading.start("checkout");
    return checkouts.get({
      id: $stateParams.checkoutId
    }, function(checkout) {
      return $scope.checkout = checkout;
    }).$promise.then(function() {
      return integrations.handle({
        checkout: $scope.checkout,
        scope: "cart"
      });
    })["catch"](function(error) {
      return $log.debug("Failed integration: " + error.message);
    })["finally"](function() {
      return loading.finish("checkout");
    });
  };
  loadShippingSuggestion = function(checkout, country_code) {
    loading.start("suggest:shipping");
    return checkouts.suggest_shipping({
      id: checkout.id,
      country_code: country_code
    }, function(suggestion) {
      $scope.availability = _.get(suggestion, "shipping_method_availability");
      if (_.isObject($scope.availability)) {
        return $log.debug("API had a shipping suggestion for '" + country_code + "'", $scope.availability);
      } else {
        return $log.debug("API had no shipping suggestion for '" + country_code + "'");
      }
    }).$promise["finally"](function() {
      return loading.finish("suggest:shipping");
    });
  };
  load = function() {
    return $q.when(true);
  };
  load().then(function() {
    return $scope.quantity_errors = {};
  }).then(function() {
    return loadCheckout();
  }).then(function() {
    var possibleCodes, possibleCountries, storeCode;
    possibleCodes = _.map($scope.checkout.store.countries, function(country) {
      return String(country.code).toUpperCase();
    });
    possibleCountries = _.filter(countries.all(), function(country) {
      var _ref;
      return _ref = country.value, __indexOf.call(possibleCodes, _ref) >= 0;
    });
    storeCode = _.get($scope.checkout, 'store.address.country_code');
    $scope.country_code = __indexOf.call(possibleCodes, storeCode) >= 0 ? storeCode : _.first(possibleCodes);
    $scope.countries = possibleCountries;
    return $scope.shippable_countries = helpers.shippableCountries($scope.checkout);
  }).then(function() {
    return loadShippingSuggestion($scope.checkout, $scope.country_code);
  });
  $scope.remove = function(checkout_item) {
    return checkout_items["delete"]({
      id: checkout_item.id
    }).$promise.then(function() {
      return $state.go($state.current, null, {
        reload: true
      });
    });
  };
  $scope.updateQuantity = function(checkout_item, new_quantity) {
    $scope.quantity_errors[checkout_item.id] = false;
    if (_.isFinite(_.parseInt(new_quantity))) {
      return checkout_items.update({
        id: checkout_item.id,
        quantity: new_quantity
      }, function(checkout_item) {
        return $state.go($state.current, null, {
          reload: true
        });
      }).$promise["catch"](function(error) {
        var key;
        key = _.get(error, "data.type", null);
        if (key === "inventory") {
          return $scope.quantity_errors[checkout_item.id] = true;
        }
      });
    }
  };
  $scope.updateCountry = function(checkout, country_code) {
    return loadShippingSuggestion(checkout, country_code);
  };
  $scope.showPaymentPayPalExpress = function(checkout) {
    if (!checkout) {
      return null;
    }
    return _.first(_.filter(checkout.payment_methods, {
      processor: {
        name: 'paypal_express'
      }
    }));
  };
  return $scope.partialPath = function(name) {
    return payment_processors.partialPath(name);
  };
});
