angular.module("wundery.checkout").factory("checkouts", function($resource, api) {
  return $resource(api.url("checkouts/:id/:action"), {
    id: "@id"
  }, {
    approve_paypal_amount: {
      method: "POST",
      params: {
        action: "approve_paypal_amount"
      }
    },
    create: {
      method: "POST"
    },
    create_or_update_payment_intent: {
      method: "POST",
      params: {
        action: "create_or_update_payment_intent"
      }
    },
    update: {
      method: "PATCH"
    },
    signin: {
      method: "PATCH",
      params: {
        action: "signin"
      }
    },
    signout: {
      method: "PATCH",
      params: {
        action: "signout"
      }
    },
    finish: {
      method: "PATCH",
      params: {
        action: "finish"
      }
    },
    suggest_shipping: {
      method: "GET",
      params: {
        action: "suggest_shipping"
      }
    },
    apply_coupon: {
      method: "POST",
      params: {
        action: "apply_coupon"
      }
    },
    remove_coupon: {
      method: "POST",
      params: {
        action: "remove_coupon"
      }
    },
    create_order: {
      method: "POST",
      params: {
        action: "paypal_express_create_order"
      }
    },
    capture_order: {
      method: "PATCH",
      params: {
        action: "paypal_express_capture_order"
      }
    },
    cancel_order: {
      method: "PATCH",
      params: {
        action: "paypal_express_cancel_order"
      }
    },
    update_shipping: {
      method: "PATCH",
      params: {
        action: "update_shipping"
      }
    },
    update_pp_shipping_costs: {
      method: "PATCH",
      params: {
        action: "update_pp_shipping_costs"
      }
    },
    create_amazon_shipping: {
      method: 'POST',
      params: {
        action: 'create_amazon_shipping'
      }
    },
    finish_by_paypal_simple: {
      method: 'PATCH',
      params: {
        action: 'finish_by_paypal_simple'
      }
    },
    validate: {
      method: "POST",
      params: {
        action: "validate"
      }
    }
  });
});
