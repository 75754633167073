angular

  .module "wundery.checkout"

  .factory "dom_manipulator", (
    $log
  ) ->
    new class integrations


      append: (options) ->
        target = _.get options, "target"
        id = _.get options, "id"
        source = _.get options, "source"
        refresh = ( _.get options, "refresh", false ) == true

        insert = ->
          $log.debug "Appending script to '#{target}'"

          angular
            .element target
            .append source

        if _.isString id

          _id = "##{id}"

          $log.debug "ID '#{_id}' is given"

          if refresh
            $log.debug "... and refresh requested. First remove, then insert"

            angular
              .element _id
              .remove()

            insert()

          else

            found = angular.element _id

            if ( _.get found, "length" ) == 0
              insert()
            else
              $log.debug "Element already exists, do not refresh"


        else
          throw new Error "An 'id' parameter must be provided"

