angular.module("wundery.checkout").factory("httpInterceptor", function($q) {
  return {
    responseError: function(response) {
      return $q.reject(response);
    },
    request: function(config) {
      return config || $q.when(config);
    }
  };
});
