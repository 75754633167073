angular

  .module "wundery.checkout"

  .filter "truncate", ->
    (text, length, end) ->
      if _.isNaN(length)
        length = 10
      if _.isUndefined end
        end = '...'
      if _.size(text) <= length or _.size(text) - _.size(end) <= length
        text
      else
        String(text).substring(0, length - _.size(end)) + end
