angular

  .module "wundery.checkout"

  .factory "finishAmazonCheckout", (
    $q
    $log
    $timeout
    $translate
    $state
    $stateParams
    $modal
    $window
    configuration
    checkouts
    validation
    countries
    creditcard
    loading
    errors
    payment_processors
    integrations
    errorCodes
    amazonPayError
  ) ->

    fallbackBillingCountryCode: (checkout) ->
      "DE"

    storeAddress: (checkout) ->
      _.result checkout.store, "address"

    shippingAddressCountryCode: (checkout) ->
      _.result checkout.shipping_address, "country_code"

    guessBillingCountryCodeIfEmpty: (checkout) ->
      unless billingAddressCountryCode checkout
        guess = storeDefaultBillingCountryCode checkout
        $log.info "Checkout's billing address country code is empty. \
                   Guessing '#{guess}'. Trigger update to show available \
                   payment and shipping options."
        checkout.billing_address = {} unless checkout.billing_address
        checkout.billing_address.country_code = guess
        update checkout

    executeFinishRequest: (checkout, authorization) ->
      params = {
        payment_authorization: authorization,
        amazon_access_token: Cookies.get('amazonAccessToken'),
        amazon_order_reference_id: Cookies.get('amazonOrderReferenceId')
      }
      checkouts
        .finish id: checkout.id, params, (response) ->
          $log.debug "Checkout finished"
          $log.info "Finished checkout"
          $state
            .go "checkout.confirmation", checkoutId: checkout.id, {
               reload: true
             }
        .$promise
        .then ->
          loading.finish "checkout:finish"
        .catch (response) ->
          $log.info "Error during finish"
          data = _.result response, "data"
          status = _.result data, "status"
          message = _.result data, "message"
          amazonPayError.addError({status: status, message: message})
          $state
            .go "checkout.confirmation", checkoutId: checkout.id, {
              reload: true
             }
          loading.finish "checkout:finish"

    currentPaymentMethod: (checkout) ->
      _.result checkout, "payment_method"

    paymentMethodData: (payment_method) ->
      $scope.payment_method_data[payment_method.id]

    billingAddressCountryCode: (checkout) ->
      _.result checkout.billing_address, "country_code"

    storeDefaultBillingCountryCode: (checkout) ->
      address = storeAddress checkout
      _.result address, "country_code", fallbackBillingCountryCode checkout

    currentPaymentProcessor: (checkout) ->
      _.result @currentPaymentMethod(checkout), "processor"


    finish: (checkout) ->
      # TODO here is the buy now button
      $log.debug "Finishing checkout ..."

      loading.start "checkout:finish"

      $log.debug "Checkout can finish ..."

      paymentMethod = @currentPaymentMethod checkout
      processor = @currentPaymentProcessor checkout
      name = _.result processor, "name"
      settings = _.result paymentMethod, "safe_settings"
      amount = _.result checkout, "total_gross_int"
      currency_code = _.result _.result(checkout, "currency"), "code"

      self = this
      payment_processors
        .validation name
        .then ->
          $log.debug "Validation successfull"
          payment_processors
            .authorization name, settings, amount, currency_code
            .then (authorization) ->
              $log.debug "Passed authorization handler ('#{authorization}')"

              self.executeFinishRequest checkout, authorization
            .catch (error) ->
              message = if error.key == 'unknown' then error.message else $translate.instant "errors.#{error.key}"

              $log.debug "Failed authorization: #{error.message}"
        .catch (errors) ->
          $log.debug "Validation failed"
