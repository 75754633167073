angular.module("wundery.checkout").factory("paymentProcessorPaystackDefinition", function($log, $q, $rootScope, $translate, configuration, checkouts, loading) {
  return {
    partial: true,
    getJSURL: function() {
      return "https://js.paystack.co/v2/inline.js";
    },
    isJSInjected: function() {
      return $("script[src='" + (this.getJSURL()) + "']").length > 0;
    },
    injectJS: function(checkout) {
      var head, script;
      if (this.isJSInjected()) {
        $log.info('paystack already injected');
        return;
      }
      $log.info('inject paystack');
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = this.getJSURL();
      head = (document.getElementsByTagName("head"))[0];
      return head.appendChild(script);
    },
    validation: function(card) {
      var deferred;
      deferred = $q.defer();
      deferred.resolve(true);
      return deferred.promise;
    },
    authorization: function(card, settings, amount, currency_code, checkout) {
      var deferred, email, paystack, publicKey;
      deferred = $q.defer();
      if (typeof PaystackPop === 'undefined') {
        deferred.reject([
          {
            message: 'PaystackPop is not available'
          }
        ]);
      }
      publicKey = _.result(_.find(settings, {
        key: 'public_key'
      }), 'value');
      email = checkout.billing_address.email;
      paystack = new PaystackPop();
      paystack.newTransaction({
        key: publicKey,
        email: email,
        amount: amount,
        currency: currency_code,
        onSuccess: function(transaction) {
          return deferred.resolve(JSON.stringify(transaction));
        },
        onCancel: function() {
          return deferred.reject('ABORT');
        },
        onError: function(error) {
          return deferred.reject([
            {
              message: error
            }
          ]);
        }
      });
      return deferred.promise;
    },
    select: function(checkout) {
      return this.injectJS(checkout);
    }
  };
}).controller("paymentProcessorPaystackCtrl", function($log, $scope, $rootScope, $translate, configuration, paymentProcessorPaystackDefinition) {
  if ($scope.checkout.payment_method && $scope.checkout.payment_method.processor.name === "paystack") {
    return paymentProcessorPaystackDefinition.select($scope.checkout);
  }
});
