angular

  .module "wundery.checkout"

  .factory "payment_processors", (
    $q
    $log
    $injector
  ) ->

    defaultValidation: (data) ->
      $log.debug "Running default validation"
      $q.when true

    defaultAuthorization: (data) ->
      $log.debug "Running default authorization"
      $q.when true

    defaultSetup: ->
      $log.debug "Running default setup"
      $q.when true

    definitionName: (name) ->
      camelized = _.camelCase name
      firstUppercased = camelized.charAt(0).toUpperCase() + camelized.slice(1)
      "paymentProcessor#{firstUppercased}Definition"

    definition: (name) ->
      definitionName = @definitionName name
      if $injector.has(definitionName)
        $injector.get(definitionName)
      else
        partial: false
        validation: @defaultValidation
        authorization: @defaultAuthorization

    hasPartial: (name) ->
      _.result(@definition(name), "partial") == true

    partialPath: (name) ->
      "app/plugins/payment_processors/#{name}/templates/#{name}.html"

    paymentMethodUpdated: (checkout) -> 

      if ! (_.has checkout, 'payment_method.processor.name')
        return

      definition = @definition(checkout.payment_method.processor.name)

      if _.has(definition, "select") && _.isFunction(definition.select)
        definition.select checkout

    validation: (name, data) ->
      $log.debug "Requesting validation for '#{name}'"

      definition = @definition(name)

      if _.has definition, "validation"
        func = definition.validation
        if _.isFunction func
          func data
        else
          @defaultValidation data
      else
        @defaultValidation data

    authorization: (name, data, settings, amount, currency_code, checkout) ->
      $log.debug "Requesting authorization for '#{name}'"
      
      definition = @definition(name)

      if _.has(definition, "authorization") && _.isFunction(definition.authorization)
        definition.authorization(data, settings, amount, currency_code, checkout)
      else
        @defaultAuthorization data

    setup: (name, settings, test_mode, checkout) ->
      $log.debug "Requesting setup for '#{name}' (test_mode = #{test_mode})"

      definition = @definition(name)

      if _.has definition, "setup"
        func = definition.setup
        if _.isFunction func
          func settings, test_mode, checkout
        else
          @defaultSetup()
      else
        @defaultSetup()

