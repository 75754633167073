angular

  .module "wundery.checkout"

  .config (
    $httpProvider
  ) ->

    ###
    Intercept all http communication
    ###

    $httpProvider.interceptors.push ($injector) ->
      $injector.get "httpInterceptor"