angular.module("wundery.checkout").directive("wcDownload", function() {
  return {
    restrict: "A",
    link: function(scope, element, attr, ctrl) {
      return element.on('click', function(event) {
        event.preventDefault();
        return saveAs(this.href, this.href.slice(this.href.lastIndexOf('/') + 1));
      });
    }
  };
});
