angular

  .module "wundery.checkout"

  .config (
    $logProvider
    environment
  ) ->

    ###
    Turn on or off debug log
    ###

    $logProvider.debugEnabled environment["debug"] == true


