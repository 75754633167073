angular

  .module "wundery.checkout"

  .config (
    $translateProvider
    translations
  ) ->

    ###
    Register translations
    ###

    _.forIn translations, (translation, locale) ->
      $translateProvider.translations locale, translation

    $translateProvider.useMissingTranslationHandler "missingTranslationHandler"

  .run (
    $translate
    $log
    configuration
    client
  ) ->

    ###
    Set locale to use
    ###

    $translate.fallbackLanguage "en"
    $translate.use configuration.locale client.locale()
    $log.debug "Selected translation '#{$translate.use()}'"

