var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

angular.module("wundery.checkout").factory("helpers", function(countries) {
  return {
    shippableCountries: function(checkout) {
      var codesWithShipping, shippable_countries;
      codesWithShipping = _.map(checkout.shippable_countries, function(country) {
        return String(country.code).toUpperCase();
      });
      shippable_countries = _.filter(countries.all(), function(country) {
        var _ref;
        return _ref = country.value, __indexOf.call(codesWithShipping, _ref) >= 0;
      });
      return shippable_countries;
    }
  };
});
