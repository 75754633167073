angular.module("wundery.checkout").factory("client", function(locale) {
  return {
    locale: function() {
      return locale.normalize(this.browserLocale());
    },
    browserLocale: function() {
      return window.navigator && window.navigator.languages && window.navigator.languages[0] || window.navigator && window.navigator.language || window.navigator && window.navigator.browserLanguage || window.navigator && window.navigator.systemLanguage || window.navigator && window.navigator.userLanguage || "en";
    }
  };
});
