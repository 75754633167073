angular

  .module "wundery.checkout"

  .factory "integrations", (
    $log
    dom_manipulator
  ) ->

    handle: (options) ->
      checkout = _.get options, "checkout"
      scope = _.get options, "scope"

      $log.debug "Handling integrations for scope '#{scope}' \
        and checkout '#{checkout.id}'"

      _.forEach ( _.get checkout, "integrations", [] ), (integration) ->
        key = _.get integration, "key"
        scopes = _.get integration, "scope"

        $log.debug "Handling integration '#{key}' (requesting '#{scopes}')"

        if _.contains scopes, scope
          $log.debug "Current scope '#{scope}' requires injection"

          target = _.get integration, "target", "body"
          source = _.get integration, "source", "<!-- No source for '#{key}' -->"

          dom_manipulator.append
            id: key
            refresh: false
            target: target
            source: source

