angular

  .module "wundery.checkout"

  .controller "OverviewCtrl", (
    $q
    $log
    $scope
    $timeout
    $translate
    $state
    $stateParams
    $modal
    $window
    configuration
    checkouts
    helpers
    validation
    countries
    loading
    errors
    payment_processors
    integrations
    $rootScope
    errorCodes
    amazonPayError
    states
  ) ->

    loadTitleState = (country) ->
      switch country
        when 'IT', 'CA', 'CN', 'PH', 'TR', 'PK'
          title = $translate.instant "PROVINCE"
        when 'RU', 'PT', 'PA', 'NZ', 'HK'
          title = $translate.instant "REGION"
        else
          title = $translate.instant "STATE"

    loadTitleAndStates = (checkout) ->
      countryBilling = _.result checkout, 'billing_address'
      countryShipping = _.result checkout, 'shipping_address'

      if countryBilling && _.result countryBilling, 'country_code'
        $scope.titleBilling = loadTitleState(countryBilling.country_code.toUpperCase())
        $scope.possible_states_billing = states.all(countryBilling.country_code.toUpperCase())

      else
        $scope.titleBilling = $translate.instant "STATE"
        $scope.possible_states_billing = []

      if countryShipping && _.result countryShipping, 'country_code'
        $scope.titleShipping = loadTitleState(countryShipping.country_code.toUpperCase())
        $scope.possible_states_shipping = states.all(countryShipping.country_code.toUpperCase())
      else
        $scope.titleShipping = $translate.instant "STATE"
        $scope.possible_states_shipping = []

    loadCheckout = ->
      loading.start "checkout"
      $scope.show_error_amazon_pay = $stateParams.show_error == "true"
      checkouts
        .get id: $stateParams.checkoutId, (checkout) ->
          $scope.checkout = checkout
        .$promise
        .then ->
          integrations.handle
            checkout: $scope.checkout
            scope: "overview"
        .finally ->
          loading.finish "checkout"

    # Update the checkout on the server.
    # Returns a promise.

    update = (checkout, permitUpdate=true) ->
      $log.info "Update checkout ..."

      loading.start "checkout:update"
      checkouts
        .update checkout, (_checkout) ->
          if permitUpdate
            $scope.checkout = _checkout
            $log.debug "Updated checkout"
        .$promise
        .finally ->
          loading.finish "checkout:update"

    # Enable validation mode. This should inform the view to show
    # hints to the user (e.g. highlight missing fields).

    $scope.activateHints = ->
      $scope.validate = true
      $scope.updateCheckout($scope.checkout).then (updatedCheckout) ->
        $scope.checkout = updatedCheckout

    $scope.can_finish_checkout = ->
      return $scope.checkout.can_finish

    showHints = ->
      $scope.validate = true

    storeAddress = (checkout) ->
      _.result checkout.store, "address"

    shippingAddressCountryCode = (checkout) ->
      _.result checkout.shipping_address, "country_code"

    # Performas the actual finish request against
    # the Branchbob API.

    executeFinishRequest = (checkout, authorization) ->
      params = {
        payment_authorization: authorization,
        amazon_access_token: Cookies.get('amazonAccessToken')
      }
      checkouts
        .finish id: checkout.id, params, (response) ->
          $log.debug "Checkout finished"
          if response.payment_result.redirecting
            $log.debug "Redirect requested, redirecting ..."
            $window.location.href = response.payment_result.redirect_url

          # TODO(PF): This is bad. The payment processors were supposed to
          # work in a generic way (stripe_connect.coffee) and should not be
          # hardcoded throughout the app.
          else if response.payment_result.requires_action
            $rootScope.stripeInstance
              .handleCardAction(response.payment_result.payment_intent_client_secret)
              .then (result) ->
                if result.error
                  $scope.errors = [{ message: result.error.message }]
                  $q.when true
                else
                  authorization = { payment_intent_id: result.paymentIntent.id }
                  executeFinishRequest(checkout, authorization)
          else
            $log.info "Finished checkout"
            $state
              .go "checkout.confirmation", checkoutId: checkout.id
              .then ->
                $timeout ->
                  $window.location.reload()
          $q.when true
        .$promise
        .catch (response) ->
          # TODO(dh) - on 422 for paypal reopen the dialog with paypal
          $log.warn "Error during finish"
          data = _.result response, "data"
          payment_result =  _.result data, "payment_result"
          code = _.result payment_result, "code"
          message = _.result payment_result, "message"
          translateMessage = if code then $translate.instant "errors.#{code}" else message
          if data.error == true
            $scope.errors = [
              key: code
              message: translateMessage
              technical_message: message
            ]
          if data.type == "inventory"
            $scope.errors = [
              code: "inventory"
              message: $translate.instant "errors.inventory"
              meta: data.meta
            ]

    validateCheckout = (checkout) ->
      checkouts
        .validate id: checkout.id
        .$promise

    setupPaymentProcessors = (checkout) ->
      $log.debug "Setup payment processors"
      processorNames = _.forEach checkout.payment_methods, (payment_method) ->
        processor = _.result payment_method, "processor"
        test_mode = _.result payment_method, "test_mode"
        name = _.result processor, "name"
        $log.info "Setup processor '#{name}'"
        payment_processors.setup name, payment_method.safe_settings, test_mode, checkout

    resetErrors = ->
      $scope.errors = []
      $q.when true

    currentPaymentMethod = (checkout) ->
      _.result checkout, "payment_method"

    paymentMethodData = (payment_method) ->
      $scope.payment_method_data[payment_method.id]

    billingAddressCountryCode = (checkout) ->
      _.result checkout.billing_address, "country_code"

    getStoreDefaultBillingCountryCode = (checkout) ->
      address = storeAddress checkout
      _.result address, "country_code"

    currentPaymentProcessor = (checkout) ->
      _.result currentPaymentMethod(checkout), "processor"

    groupFieldsIntoRows = (fields) ->
      rows = []
      # Sort fields by their y_position, then by x_position
      sortedFields = fields.sort((a, b) ->
        if a.y_position != b.y_position then a.y_position - b.y_position else a.x_position - b.x_position
      )
      groupedByY = {}
      sortedFields.forEach (field) ->
        groupedByY[field.y_position] ?= []
        groupedByY[field.y_position].push(Object.assign({}, field, { gridWidth: field.width }))
      # Convert grouped fields into an array of rows with offsets
      Object.keys(groupedByY).sort((a, b) -> parseInt(a) - parseInt(b)).forEach (key) ->
        row = groupedByY[key]
        row.forEach (field, index) ->
          # Add offset to the first field in the row only
          if index == 0
            field.offset = if field.x_position > 0 then field.x_position else 0
          else
            # No offset for other fields in the same row
            field.offset = 0
        rows.push(row)
      rows

    # Bootstraps the view. Watch for important changes
    # and trigger the apropriate action.

    load = ->
      $log.debug "Loading checkout overview"
      $scope.validate = false
      amazonPayError.clearError()
      loadCheckout()
        .then ->
          $scope.payment_method_data = {}
          $scope.isEmail = validation.isEmail
          $scope.isDevelopment = configuration.isDevelopment()
          $scope.newCouponCode = ''
          $scope.possible_countries = countries.all()
          $scope.shippable_countries = helpers.shippableCountries($scope.checkout)
          $scope.error = false

          $scope.checkout_form_items = groupFieldsIntoRows($scope.checkout.checkout_form_items)
          $scope.checkAvailablePaymentMethods()

          setupPaymentProcessors $scope.checkout

          loadTitleAndStates($scope.checkout)

        .$promise

    # Finally load some defaults

    load()

    # Returns whether the checkout is possible or disabled
    # out of any reasons.

    $scope.isCheckoutable = (checkout) ->
      ( _.result checkout, "disabled" ) == false

    # Returns whether a shipping method exists, that could
    # be used for this checkout in its current state.

    $scope.hasAvailableShippingMethod = (checkout) ->
      _.some(
        _.map(
          checkout.shipping_method_availabilities
          (shipping_method_availability) ->
            shipping_method_availability.available == true
        )
        (value) -> value == true
      )

    # Returns whether a payment method exists, that could
    # be used for this checkout in its current state.

    $scope.hasAvailablePaymentMethod = (checkout) ->
      _.some(
        _.map(
          checkout.payment_method_availabilities
          (payment_method_availability) ->
            payment_method_availability.available == true
        )
        (value) -> value == true
      )

    $scope.applyCoupon = (newCouponCode, checkout) ->
      loading.start "apply_coupon"
      update checkout
        .finally ->
          checkouts
            .apply_coupon id: $stateParams.checkoutId, coupon_code: newCouponCode, (checkout) ->
              $scope.checkout = checkout
              $scope.newCouponCode = ''
            .$promise
            .catch (error) ->
              if error.status == 404
                switch error.data.code
                  when errorCodes.coupon.invalidDiscount
                    msg = 'errors.apply_coupon_too_high'
                  when errorCodes.coupon.invalidTimesUsed
                    msg = 'errors.exceed_usage_limitation_of_coupon'
                  else
                    msg = 'errors.apply_coupon'
                alert $translate.instant msg
            .finally ->
              loading.finish "apply_coupon"

    $scope.removeCoupon = (newCouponCode) ->
      loading.start "remove_coupon"
      checkouts
        .remove_coupon id: $stateParams.checkoutId, (checkout) ->
          $scope.checkout = checkout
        .$promise
        .finally ->
          loading.finish "remove_coupon"

    $scope.loadDummyBillingAddress = (checkout) ->
      $log.debug "Loading dummy billing address ..."
      checkout.billing_address =
        first_name: "Jon"
        last_name: "Doe"
        street: "Doe Rd"
        street_no: "42"
        country_code: "DE"
        zip: "1337"
        city: "Doesen"
        email: "doe@example.com"
        company: "Doe Industries Ltd."
        phone: "+49111111111111"
      update checkout

    $scope.resetBillingAddress = (checkout) ->
      $log.debug "Resetting dummy billing address ..."
      checkout.billing_address =
        first_name: ""
        last_name: ""
        street: ""
        street_no: ""
        country_code: ""
        zip: ""
        city: ""
        email: ""
        company: ""
      update checkout

    $scope.dummySignin = (checkout) ->
      $log.debug "signing in ..."
      $scope.signin checkout, "dexter@example.com", "secret"

    $scope.loadDummyShippingAddress = (checkout) ->
      $log.debug "Loading dummy shipping address ..."
      checkout.separate_shipping_address = true
      checkout.shipping_address =
        first_name: "Maria"
        last_name: "Doe"
        street: "Doesen Circle"
        street_no: "7"
        country_code: "DE"
        zip: "4042"
        city: "Doesen"
        email: "doe@example.com"
      update checkout

    $scope.debugCheckout = (checkout) ->
      checkouts
        .get id: checkout.id, (_checkout) ->
          instance = $modal.open
            template: '<div class="modal-body"><pre>{{ checkout | json }}</pre></div>'
            size: "lg"
            resolve:
              checkout: -> _checkout
            controller: (
              $scope
              checkout
            ) ->
              $scope.checkout = checkout

    $scope.signout = (checkout) ->
      loading.start "signout"
      checkouts
        .signout id: checkout.id, (_checkout) ->
          $scope.checkout = _checkout
        .$promise
        .finally ->
          $scope.email = null
          $scope.password = null
          loading.finish "signout"

    $scope.signin = (checkout, email, password) ->
      $log.debug "Signing in '#{email}' on checkout '#{checkout.id}'"
      $scope.error = false
      loading.start "signin"
      checkouts
        .signin id: checkout.id, email: email, password: password, checkout_page: true, (_checkout) ->
          $scope.checkout = _checkout
          messsage = $translate.instant 'CART_UPDATED'
          close = $translate.instant 'common.close'
          $modal.open
            template: "
              <div class='modal-body'>#{messsage}</div>
              <div class='modal-footer'>
                <button type='button' class='btn btn-secondary' ng-click='closeModal()'>#{close}</button>
              </div>
            "
            controller: ($scope, $modalInstance) ->
              $scope.closeModal = () ->
                $modalInstance.close()
                $state
                  .go "checkout.overview", checkoutId: checkout.id

            size: "lg"

        .$promise
        .catch ->
          $scope.error = true
        .finally ->
          loading.finish "signin"

    $scope.updateCheckout = (checkout) ->
      update checkout

    $scope.billingAddressCountryCodeChanged = (checkout) ->
      checkout.billing_address.state_code = null
      checkout.billing_state_code = null
      update checkout

    $scope.shippingAddressCountryCodeChanged = (checkout) ->
      checkout.shipping_address.state_code = null
      checkout.shipping_state_code = null
      update checkout

    $scope.enableSeparateShippingAddress = (checkout) ->
      $scope.checkout.separate_shipping_address = true
      $log.info "Separate shipping address requested. Trigger \
                 checkout update ..."
      update checkout

    $scope.disableSeparateShippingAddress = (checkout) ->
      $scope.checkout.separate_shipping_address = false
      $log.info "Separate shipping address disabled. Trigger \
                 checkout update ..."
      update checkout

    $scope.isSeparateShippingAddressEnabled = (checkout) ->
      $scope.checkout.separate_shipping_address == true

    $scope.shippingMethodContext = (shipping_method, checkout) ->
      _.first(
        _.filter(
          checkout.shipping_method_availabilities
          shipping_method_id: shipping_method.id
        )
      )

    $scope.isShippingMethodAvailable = (shipping_method, checkout) ->
      context = $scope.shippingMethodContext(shipping_method, checkout)
      context.available == true

    $scope.shippingMethodChanged = (checkout) ->
      $log.info "Shipping method changed to '#{checkout.shipping_method_id}'"

      update checkout

    $scope.paymentMethodChanged = (checkout) ->
      $log.info "requested payment method change '#{checkout.payment_method_id}'"

      # TODO: fix this
      if $rootScope.stripeCard && $rootScope.stripeCard.cardElement
        $rootScope.stripeCard.cardElement.unmount()

      update checkout
        .then (updatedCheckout) ->
          $log.info "Payment method changed to '#{checkout.payment_method_id}'"
          payment_processors.paymentMethodUpdated updatedCheckout

    $scope.paymentMethodContext = (payment_method, checkout) ->
      _.first(
        _.filter(
          checkout.payment_method_availabilities
          payment_method_id: payment_method.id
        )
      )

    $scope.persistPassword = (checkout) ->
      update checkout

    $scope.createPasswordChanged = (checkout) ->
      unless checkout.create_password
        $log.info "Password removal requested. Trigger update ..."
        update id: checkout.id, password: "", create_password: false

    $scope.unsetPassword = (checkout) ->
      $log.info "Password reset requested. Trigger update ..."
      update id: checkout.id, password: ""

    $scope.isPaymentMethodAvailable = (payment_method, checkout) ->
      context = $scope.paymentMethodContext(payment_method, checkout)
      context.available == true

    $scope.isCurrentPaymentMethod = (payment_method, checkout) ->
      ( _.result checkout, "payment_method_id" ) == ( _.result payment_method, "id" )

    $scope.hasPartial = (payment_method) ->
      payment_processors.hasPartial payment_method.processor.name

    $scope.partialPath = (payment_method) ->
      payment_processors.partialPath payment_method.processor.name

    $scope.showPaymentPayPalExpress = (checkout) ->
      return null unless checkout
      _.first(
        _.filter(
          checkout.payment_methods,
          { processor: { name: 'paypal_express' } }
        )
      )

    $scope.finish = (checkout) ->
      $log.debug "Finishing checkout ..."

      loading.start "checkout:finish"

      resetErrors()
        .then ->
          update checkout, false
        .then (checkout) ->
          if checkout.can_finish
            $log.debug "Checkout can finish ..."

            # Validate the availability of products in checkout first.
            validateCheckout(checkout)
              .then ->
                $log.info "Checkout validation successful"
                # Determine the current processor definition.
                paymentMethod = currentPaymentMethod checkout
                processor = currentPaymentProcessor checkout
                name = _.result processor, "name"
                data = paymentMethodData paymentMethod
                settings = _.result paymentMethod, "safe_settings"
                amount = _.result checkout, "total_gross_int"
                currency_code = _.result _.result(checkout, "currency"), "code"

                # Validate the used payment medium.
                payment_processors
                  .validation name, data
                  .then ->
                    $log.info "Processor validation successful"
                    payment_processors
                      .authorization name, data, settings, amount, currency_code, checkout
                      .then (authorization) ->
                        $log.info "Succeeded authorization, finishing checkout..."
                        executeFinishRequest checkout, authorization
                      .catch (errors) ->
                        return if errors == 'ABORT'
                        $log.info "Failed authorization: #{JSON.stringify(errors)}"
                        $scope.errors = _.map errors, (error) ->
                          if error.message
                            { message: error.message }
                          else
                            {
                              key: error.key
                              message: $translate.instant "errors.#{error.key}"
                            }
                  .catch (errors) ->
                    $scope.errors = _.map errors, (error) ->
                      if error.message
                        { message: error.message }
                      else
                        {
                          key: error.key
                          message: $translate.instant "errors.#{error.key}"
                        }
                    $log.debug "Validation failed"
              .catch (errors) ->
                data = _.result errors, "data"

                if data.type == "inventory"
                  $scope.errors = [
                    code: "inventory"
                    message: $translate.instant "errors.inventory"
                    meta: data.meta
                  ]
          else
            showHints()
            $log.info "Checkout cannot finish, do nothing (Reason returned from API: '#{checkout.explain}')"

        .finally ->
          loading.finish "checkout:finish"

    $scope.createCustomerChanged = (checkout) ->
      if checkout.store.allows_returning_customers
        update checkout

    $scope.skipValidateStreetNo = (checkout, billing=true) ->
      unless checkout.processor_paypal_express
        if billing
          $scope.validate && !checkout.billing_address.street_no
        else
          $scope.validate && checkout.separate_shipping_address && !checkout.shipping_address.street_no

    # Check if the merchant is located in Germany or Austria
    $scope.isGermanCountry = (country_code) ->
      country_code in ['DE', 'AT']

    $scope.checkAvailablePaymentMethods = () ->
      availablePaymentMethods = $scope.checkout.payment_methods.filter((method) =>
        return $scope.isPaymentMethodAvailable(method, $scope.checkout)
      )
      availableShippingMethods = $scope.checkout.shipping_methods.filter((method) =>
        return $scope.isShippingMethodAvailable(method, $scope.checkout)
      )
      # Automatically select the payment method if only one is available
      if availablePaymentMethods.length == 1
        $scope.checkout.payment_method_id = availablePaymentMethods[0].id
      # Automatically select the shipping method if only one is available
      if availableShippingMethods.length == 1
        $scope.checkout.shipping_method_id = availableShippingMethods[0].id

      if $scope.checkout.payment_method_id || $scope.checkout.shipping_method_id
        update $scope.checkout
