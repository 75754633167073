angular.module("wundery.checkout").factory("finishAmazonCheckout", function($q, $log, $timeout, $translate, $state, $stateParams, $modal, $window, configuration, checkouts, validation, countries, creditcard, loading, errors, payment_processors, integrations, errorCodes, amazonPayError) {
  return {
    fallbackBillingCountryCode: function(checkout) {
      return "DE";
    },
    storeAddress: function(checkout) {
      return _.result(checkout.store, "address");
    },
    shippingAddressCountryCode: function(checkout) {
      return _.result(checkout.shipping_address, "country_code");
    },
    guessBillingCountryCodeIfEmpty: function(checkout) {
      var guess;
      if (!billingAddressCountryCode(checkout)) {
        guess = storeDefaultBillingCountryCode(checkout);
        $log.info("Checkout's billing address country code is empty. Guessing '" + guess + "'. Trigger update to show available payment and shipping options.");
        if (!checkout.billing_address) {
          checkout.billing_address = {};
        }
        checkout.billing_address.country_code = guess;
        return update(checkout);
      }
    },
    executeFinishRequest: function(checkout, authorization) {
      var params;
      params = {
        payment_authorization: authorization,
        amazon_access_token: Cookies.get('amazonAccessToken'),
        amazon_order_reference_id: Cookies.get('amazonOrderReferenceId')
      };
      return checkouts.finish({
        id: checkout.id
      }, params, function(response) {
        $log.debug("Checkout finished");
        $log.info("Finished checkout");
        return $state.go("checkout.confirmation", {
          checkoutId: checkout.id
        }, {
          reload: true
        });
      }).$promise.then(function() {
        return loading.finish("checkout:finish");
      })["catch"](function(response) {
        var data, message, status;
        $log.info("Error during finish");
        data = _.result(response, "data");
        status = _.result(data, "status");
        message = _.result(data, "message");
        amazonPayError.addError({
          status: status,
          message: message
        });
        $state.go("checkout.confirmation", {
          checkoutId: checkout.id
        }, {
          reload: true
        });
        return loading.finish("checkout:finish");
      });
    },
    currentPaymentMethod: function(checkout) {
      return _.result(checkout, "payment_method");
    },
    paymentMethodData: function(payment_method) {
      return $scope.payment_method_data[payment_method.id];
    },
    billingAddressCountryCode: function(checkout) {
      return _.result(checkout.billing_address, "country_code");
    },
    storeDefaultBillingCountryCode: function(checkout) {
      var address;
      address = storeAddress(checkout);
      return _.result(address, "country_code", fallbackBillingCountryCode(checkout));
    },
    currentPaymentProcessor: function(checkout) {
      return _.result(this.currentPaymentMethod(checkout), "processor");
    },
    finish: function(checkout) {
      var amount, currency_code, name, paymentMethod, processor, self, settings;
      $log.debug("Finishing checkout ...");
      loading.start("checkout:finish");
      $log.debug("Checkout can finish ...");
      paymentMethod = this.currentPaymentMethod(checkout);
      processor = this.currentPaymentProcessor(checkout);
      name = _.result(processor, "name");
      settings = _.result(paymentMethod, "safe_settings");
      amount = _.result(checkout, "total_gross_int");
      currency_code = _.result(_.result(checkout, "currency"), "code");
      self = this;
      return payment_processors.validation(name).then(function() {
        $log.debug("Validation successfull");
        return payment_processors.authorization(name, settings, amount, currency_code).then(function(authorization) {
          $log.debug("Passed authorization handler ('" + authorization + "')");
          return self.executeFinishRequest(checkout, authorization);
        })["catch"](function(error) {
          var message;
          message = error.key === 'unknown' ? error.message : $translate.instant("errors." + error.key);
          return $log.debug("Failed authorization: " + error.message);
        });
      })["catch"](function(errors) {
        return $log.debug("Validation failed");
      });
    }
  };
});
