angular

  .module "wundery.checkout", [

    "wundery.translations"
    "wundery.environment"
    "wundery.vendor"

  ]

  .run (
    $log
    environmentName
    gitRevision
  ) ->

    $log.info "booting Branchbob Checkout (#{environmentName} @ revision '#{gitRevision}')"
