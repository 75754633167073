angular
  .module "wundery.checkout"
  .factory "paymentProcessorDlocalDefinition", (
    $log
    $q
    $rootScope
    $translate
    configuration
    checkouts
    loading
  ) ->
    partial: true

    # invoked by the checkout framework
    validation: (card) ->
      deferred = $q.defer()
      deferred.resolve true
      deferred.promise

    # invoked by the checkout framework
    authorization: (card, settings, amount, currency_code, checkout) ->
      deferred = $q.defer()

      deferred.resolve(
        JSON.stringify({
          amount: checkout.total_gross
          currency: currency_code
          country: checkout.billing_address.country_code
          payment_method_flow: 'REDIRECT'
          payer: {
            name: checkout.billing_address.full_name
            email: checkout.billing_address.email
            phone: checkout.billing_address.phone
            address: {
              city: checkout.billing_address.city
              street: checkout.billing_address.street
              number: checkout.billing_address.street_no
              zip_code: checkout.billing_address.zip
            }
          },
          order_id: generateRandomString()
          # URL where dlocal will send notifications associated to changes to this payment.
          notification_url: checkout.confirm_dlocal_payment_url
          # URL where dLocal does the final redirect. Required only for REDIRECT payment flow.
          callback_url: checkout.confirm_dlocal_payment_url
          # TODO(ken) - Can we improve this?
          # Using the payment_intent_id in the authorization forces returning
          # the existing checkout.order if existing in the checkouts#finish api.
          payment_intent_id: checkout.id
        })
      )

      deferred.promise

  .controller "paymentProcessorDlocalCtrl", (
    $log
    $scope
    $rootScope
    $translate
    configuration
    paymentProcessorDlocalDefinition
  ) ->
    ;
