angular

  .module "wundery.checkout"

  .factory "amazonPayError", (
    $rootScope
  ) ->
    $rootScope.error = {}
    addError: (error) ->
      $rootScope.error = error
    getError: () ->
      $rootScope.error
    clearError: () ->
      $rootScope.error = {}
