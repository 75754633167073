angular.module("wundery.checkout").factory("paymentProcessorRazorpayDefinition", function($log, $q, $rootScope, $translate, configuration, checkouts, loading) {
  return {
    partial: true,
    getJSURL: function() {
      return "https://checkout.razorpay.com/v1/checkout.js";
    },
    isJSInjected: function() {
      return $("script[src='" + (this.getJSURL()) + "']").length > 0;
    },
    getIntentToken: function(checkout) {
      var deferred;
      deferred = $q.defer();
      loading.start("checkout:update");
      checkouts.create_or_update_payment_intent(checkout, function(_checkout) {
        $rootScope.checkout = _checkout;
        return deferred.resolve(_checkout.payment_intent_token);
      }).$promise["catch"](function(err) {
        return deferred.reject('Unable to retrieve payment intent');
      })["finally"](function() {
        return loading.finish("checkout:update");
      });
      return deferred.promise;
    },
    injectJS: function(checkout) {
      var head, script;
      if (this.isJSInjected()) {
        $log.info('razorpay.js already injected');
        return;
      }
      $log.info('inject razorpay.js');
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = this.getJSURL();
      head = (document.getElementsByTagName("head"))[0];
      return head.appendChild(script);
    },
    authorization: function(card, settings, amount, currency_code, checkout) {
      var deferred;
      deferred = $q.defer();
      if (typeof Razorpay === 'undefined') {
        deferred.reject([
          {
            message: "Razorpay is not available"
          }
        ]);
      } else {
        this.getIntentToken(checkout).then(function(payment_intent_token) {
          var keyId, options, razorpayInstance;
          keyId = _.result(_.find(settings, {
            key: "key_id"
          }), "value");
          options = {
            key: keyId,
            amount: amount,
            currency: currency_code,
            name: checkout.store.title,
            order_id: payment_intent_token,
            prefill: {
              email: checkout.billing_address.email,
              contact: checkout.billing_address.phone
            },
            handler: function(response) {
              return deferred.resolve(JSON.stringify(response));
            },
            modal: {
              ondismiss: function() {
                return deferred.reject('ABORT');
              }
            }
          };
          razorpayInstance = new Razorpay(options);
          razorpayInstance.on('payment.failed', function(response) {
            var message;
            message = "" + response.error.description + " (" + response.error.code + ")";
            return deferred.reject([
              {
                message: message
              }
            ]);
          });
          return razorpayInstance.open();
        })["catch"](function(err) {
          return deferred.reject([
            {
              message: err
            }
          ]);
        });
      }
      return deferred.promise;
    },
    validation: function(card) {
      var deferred;
      deferred = $q.defer();
      deferred.resolve(true);
      return deferred.promise;
    },
    select: function(checkout) {
      return this.injectJS(checkout);
    }
  };
}).controller("paymentProcessorRazorpayCtrl", function($log, $scope, $rootScope, $translate, configuration, paymentProcessorRazorpayDefinition) {
  if ($scope.checkout.payment_method && $scope.checkout.payment_method.processor.name === "razorpay") {
    return paymentProcessorRazorpayDefinition.select($scope.checkout);
  }
});
