angular

  .module "wundery.checkout"

  .factory "states", (
    $translate
    translations
  ) ->

    all: (key) ->
      _.map translations[$translate.use()]["STATES"][key], (title, value) ->
        title: title
        value: value



