angular

  .module "wundery.checkout"

  .factory "paymentProcessorTwoCheckoutDefinition", (
    $log
    $q
    $rootScope
    $translate
    configuration
    checkouts
    loading
  ) ->
    partial: true
    getJSURL: -> "https://secure.2checkout.com/checkout/client/twoCoInlineCart.js"
    
    isJSInjected: -> $("script[src='#{@getJSURL()}']").length > 0 

    injectJS: (checkout) ->
      if @isJSInjected()
        $log.info '2co already injected'
        return

      $log.info 'inject 2co'
      
      # see: https://verifone.cloud/docs/2checkout/Documentation/07Commerce/InLine-Checkout-Guide/02InLine-Checkout-ordering-engine
      
      script = document.createElement "script"
      script.type = "text/javascript"
      script.src = @getJSURL()
      script.async = true
      script.onload = ->
        if window.TwoCoInlineCart
          config = 
            cart:
              host: "https://secure.2checkout.com"
          
          _.forEach (_.keys config), (key) ->    
            window.TwoCoInlineCart.setup.setConfig(key, config[key]);  

          window.TwoCoInlineCart.register()

      head = (document.getElementsByTagName "head")[0]            
      head.appendChild script


    # invoked by the checkout framework
    authorization: (card, settings, amount, currency_code, checkout) ->
      deferred = $q.defer()
      merchantCode = _.result _.find(settings, key: "merchant_code"), "value"
      
      TwoCoInlineCart.setup.setMerchant merchantCode
      
      # erase previous cart sessions
      TwoCoInlineCart.cart.setReset true

      # required to add products dynamically; otherwise, 
      # maintenance of products would need to happen in the 2CO panel
      TwoCoInlineCart.setup.setMode 'DYNAMIC'
      TwoCoInlineCart.cart.setCurrency currency_code 
      TwoCoInlineCart.cart.setCartLockedFlag true
      TwoCoInlineCart.cart.setLanguage $translate.use()
      TwoCoInlineCart.cart.setAutoAdvance true
      TwoCoInlineCart.billing.setName checkout.billing_address.full_name
      TwoCoInlineCart.billing.setCountry checkout.billing_address.country_code
      TwoCoInlineCart.billing.setEmail checkout.billing_address.email
      TwoCoInlineCart.cart.setReturnMethod
        type: 'redirect'
        url : checkout.confirm_url

      isTestMode = _.result checkout.payment_method, "test_mode"

      if isTestMode
        $log.info "using test mode"
        TwoCoInlineCart.cart.setTest true

      # triggered when the modal is closed
      TwoCoInlineCart.events.subscribe 'cart:closed', ->
        deferred.reject 'ABORT'

      # we don't replicate our internal cart here and instead
      # simply add one cart item representing the total cart value
      TwoCoInlineCart.products.add
        name: 'Cart items'
        quantity: 1
        price: checkout.total_gross
      
      TwoCoInlineCart.cart.checkout()
    
      deferred.promise

    # invoked by the checkout framework
    validation: (card) ->
      deferred = $q.defer()
      deferred.resolve true
      deferred.promise

    # invoked by the checkout framework when a 
    # payment method using this processor is selected
    select: (checkout) -> 
      @injectJS checkout
      

  .controller "paymentProcessorTwoCheckoutCtrl", (
    $log
    $scope
    $rootScope
    $translate
    configuration
    paymentProcessorTwoCheckoutDefinition
  ) ->
    if $scope.checkout.payment_method && $scope.checkout.payment_method.processor.name == "two_checkout"
      paymentProcessorTwoCheckoutDefinition.select $scope.checkout
