angular

  .module "wundery.checkout"

  .factory "paymentProcessorPaystackDefinition", (
    $log
    $q
    $rootScope
    $translate
    configuration
    checkouts
    loading
  ) ->
    partial: true
    getJSURL: -> "https://js.paystack.co/v2/inline.js"

    isJSInjected: -> $("script[src='#{@getJSURL()}']").length > 0

    injectJS: (checkout) ->
      if @isJSInjected()
        $log.info 'paystack already injected'
        return

      $log.info 'inject paystack'

      # see: https://paystack.com/docs/payments/accept-payments/#popup
      script = document.createElement "script"
      script.type = "text/javascript"
      script.src = @getJSURL()
      head = (document.getElementsByTagName "head")[0]
      head.appendChild script

    # invoked by the checkout framework
    validation: (card) ->
      deferred = $q.defer()
      deferred.resolve true
      deferred.promise

    # invoked by the checkout framework
    authorization: (card, settings, amount, currency_code, checkout) ->
      deferred = $q.defer()
      if typeof PaystackPop == 'undefined'
        deferred.reject [{ message: 'PaystackPop is not available' }]

      publicKey = _.result _.find(settings, key: 'public_key'), 'value'
      email = checkout.billing_address.email

      # see: https://paystack.com/docs/guides/migrating-from-inlinejs-v1-to-v2/#updating-methods
      paystack = new PaystackPop()
      # see: https://paystack.com/docs/developer-tools/inlinejs/#newtransactionoptions
      paystack.newTransaction({
        key: publicKey
        email: email
        amount: amount
        currency: currency_code
        onSuccess: (transaction) ->
          deferred.resolve(JSON.stringify(transaction))
        onCancel: () ->
          deferred.reject 'ABORT'
        onError: (error) ->
          deferred.reject [{ message: error }]
      });

      deferred.promise

    # invoked by the checkout framework when a
    # payment method using this processor is selected
    select: (checkout) ->
      @injectJS checkout

  .controller "paymentProcessorPaystackCtrl", (
    $log
    $scope
    $rootScope
    $translate
    configuration
    paymentProcessorPaystackDefinition
  ) ->
    if $scope.checkout.payment_method && $scope.checkout.payment_method.processor.name == "paystack"
      paymentProcessorPaystackDefinition.select $scope.checkout
