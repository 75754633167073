angular

  .module "wundery.checkout"

  .factory "errors", ->

    parse: (error) ->
      response = _.result error, "data"

      errors = []

      errors.push
        key: _.result response, "key"
        message: _.result response, "message"

      errors




