angular

  .module "wundery.checkout"

  .factory "helpers", (
    countries
  ) ->

    shippableCountries: (checkout) ->
      codesWithShipping = _.map checkout.shippable_countries, (country) ->
        String(country.code).toUpperCase()

      shippable_countries = _.filter countries.all(), (country) ->
        country.value in codesWithShipping

      shippable_countries
