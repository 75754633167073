angular

  .module "wundery.vendor", [

    # Official angular dependencies

    "ngResource"
    "ngSanitize"

    # Other dependencies

    "ui.router"
    "ui.bootstrap"

    "pascalprecht.translate"
  ]


