angular.module("wundery.checkout").factory("api", function(configuration) {
  return {
    base: function() {
      return configuration.read("api_url");
    },
    url: function(path) {
      return "" + (this.base()) + "/storefront_api/v1/" + path;
    }
  };
});
