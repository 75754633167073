angular

  .module "wundery.checkout"

  .factory "paymentProcessorAmazonPayDefinition", (
    $q
    $log
    checkouts
    $modal
    $state
    $timeout
    finishAmazonCheckout
    configuration
  ) ->
    partial: true

    setup: (settings, test_mode, checkout) ->
      merchant_id = _.result _.find(settings, key: "merchant_id"), "value"
      client_id = _.result _.find(settings, key: "client_id"), "value"
      region = _.result _.find(settings, key: "region"), "value"

      if !region
        region = "de"
      src_path = "amazon_pay_urls"
      if configuration.isProduction() && test_mode
        src_path = "test_mode.amazon_pay_urls"
      src = configuration.read "#{src_path}.#{region}"
      $log.info "Injecting amazon pay library (test_mode = #{test_mode})"
      head = ( document.getElementsByTagName "head" )[0]
      script = document.createElement "script"
      script.type = "text/javascript"

      script.src = src
      head.appendChild script

      window.onAmazonLoginReady = ->
        amazon.Login.setClientId client_id;

      window.onAmazonPaymentsReady = ->
        $log.info 'Injecting Amazon Pay button (onAmazonPaymentsReady invoked)'
        authRequest = undefined
        params =
          type: 'PwA'
          authorization: ->
            loginOptions = {scope: "profile payments:widget payments:shipping_address", popup: true}
            authRequest = amazon.Login.authorize loginOptions, (t) ->
              Cookies.set('amazonAccessToken', t.access_token)
              $modal.open
                template: amazonPayWidgetTemplate()
                backdropClass: 'amazon-backdrop'
                controller: ($scope, $modalInstance) ->
                  $scope.buy = () ->
                    $modalInstance.close()
                    $state
                     .go "checkout.confirmation", checkoutId: checkout.id
                    finishAmazonCheckout
                      .finish(checkout)
                      .$promise
                      .then ->
                        logoutAmazon()
                  $modalInstance.result.finally = ->
                    resetAmazonBuyBtn()
              showAddressBookWidget()
          onError: (error) ->
            $log.debug "ERROR: Code = #{error.getErrorCode()}; Message = #{error.getErrorMessage()}"

        window.OffAmazonPayments.Button "amazon-pay-button", merchant_id, params

        showAddressBookWidget()

      showAddressBookWidget = ->
        new (OffAmazonPayments.Widgets.AddressBook)(
          sellerId: merchant_id
          onReady: (orderReference) ->
            disableAmazonBuyBtn()
            orderReferenceId = orderReference.getAmazonOrderReferenceId()
            Cookies.set('amazonOrderReferenceId', orderReferenceId)
            checkouts
              .create_amazon_shipping id: checkout.id, {
                amazon_order_reference_id: orderReferenceId,
                amazon_access_token: Cookies.get('amazonAccessToken')
              }
              .$promise
              .then (response) ->
                resetAmazonBuyBtn()
            showWalletWidget(orderReferenceId)
          onOrderReferenceCreate: (orderReference) ->
          onAddressSelect: (t)->
          design: designMode: 'responsive'
          onError: (error) ->
            $log.info "ERROR: Code = #{error.getErrorCode()}; Message = #{error.getErrorMessage()}"
            showErrorModal(error.getErrorMessage())
        ).bind 'amazon-pay-address-book'

      showWalletWidget = (orderReferenceId) ->
        new (OffAmazonPayments.Widgets.Wallet)(
          sellerId: merchant_id
          amazonOrderReferenceId: orderReferenceId
          onReady: (orderReference) ->
          onPaymentSelect: (orderReference) ->
          design: designMode: 'responsive'
          onError: (error) ->
            $log.debug "ERROR: Code = #{error.getErrorCode()}; Message = #{error.getErrorMessage()}"
        ).bind 'amazon-pay-wallet'

      amazonPayWidgetTemplate = ->
        "<div class='modal-body'>
          <div id='amazon-pay-address-book' class='show-amazon-pay-wallet'></div>
          <div id='amazon-pay-wallet' class='show-amazon-pay-wallet'></div>
        </div>
        <div class='modal-footer wy-text-left'>
          <button type='button' id='amazon-buy-btn' class='wy-button wy-button-success wy-button-block wy-button-large margin-bottom-half' ng-click='buy()'>Buy Now</button>
          <div id='amazon-error' class='label label-danger space-default'></div>
        </div>"

      logoutAmazon = ->
        Cookies.set('amazonAccessToken', '')
        amazon.Login.logout()

      resetAmazonBuyBtn = ->
        $('#amazon-buy-btn').prop('disabled', false)

      disableAmazonBuyBtn = ->
        $('#amazon-buy-btn').prop('disabled', true)

      showErrorModal = (errorMsg) ->
        disableAmazonBuyBtn()
        $('#amazon-pay-wallet')
          .removeClass('show-amazon-pay-wallet')
          .addClass('hide-amazon-pay-wallet')
        $('#amazon-error').text(errorMsg)

    authorization:
      (card, settings) ->
        $q.when true

    validation:
      (card) ->
        $q.when true

  .controller "paymentProcessorAmazonPayCtrl", (
    $scope
    creditcard
  ) ->
    ;
