angular

  .module "wundery.checkout"

  .directive "icon", ->
    restrict: "E"
    template: "<i class=\"fa fa-{{ symbol }}\"></i>&nbsp;"
    scope:
      loading: "="
    link: (scope, element, attrs) ->
      scope.symbol = _.result attrs, "symbol"
      scope.$watch(
        -> scope.loading
        (newValue, oldValue) ->
          if newValue != oldValue
            if newValue == true
              scope.symbol = "circle-o-notch fa-spin"
            else
              scope.symbol = _.result attrs, "symbol"
      )
