angular.module("wundery.checkout").factory("states", function($translate, translations) {
  return {
    all: function(key) {
      return _.map(translations[$translate.use()]["STATES"][key], function(title, value) {
        return {
          title: title,
          value: value
        };
      });
    }
  };
});
