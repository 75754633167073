angular.module("wundery.checkout").factory("checkout_items", function($resource, api) {
  return $resource(api.url("checkout_items/:id"), {
    id: "@id"
  }, {
    update: {
      method: "PATCH"
    },
    "delete": {
      method: "DELETE"
    }
  });
});
