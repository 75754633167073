angular

  .module "wundery.checkout"

  .controller "CreditCardFormCtrl", (
    $scope
    creditcard
  ) ->

    $scope.creditcardIcon = (number) ->
      brand = creditcard.brand(number) || "unknown"
      "images/card-#{brand}.png"
