angular.module("wundery.checkout").directive("icon", function() {
  return {
    restrict: "E",
    template: "<i class=\"fa fa-{{ symbol }}\"></i>&nbsp;",
    scope: {
      loading: "="
    },
    link: function(scope, element, attrs) {
      scope.symbol = _.result(attrs, "symbol");
      return scope.$watch(function() {
        return scope.loading;
      }, function(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === true) {
            return scope.symbol = "circle-o-notch fa-spin";
          } else {
            return scope.symbol = _.result(attrs, "symbol");
          }
        }
      });
    }
  };
});
