angular

  .module "wundery.checkout"

  .factory "checkout_items", (
    $resource
    api
  ) ->
    $resource(
      api.url("checkout_items/:id")
      (
        id: "@id"
      )
      (
        update:
          method: "PATCH"
        delete:
          method: "DELETE"
      )
    )