angular.module("wundery.checkout").factory("errors", function() {
  return {
    parse: function(error) {
      var errors, response;
      response = _.result(error, "data");
      errors = [];
      errors.push({
        key: _.result(response, "key"),
        message: _.result(response, "message")
      });
      return errors;
    }
  };
});
