angular.module("wundery.checkout").factory("paymentProcessorDlocalDefinition", function($log, $q, $rootScope, $translate, configuration, checkouts, loading) {
  return {
    partial: true,
    validation: function(card) {
      var deferred;
      deferred = $q.defer();
      deferred.resolve(true);
      return deferred.promise;
    },
    authorization: function(card, settings, amount, currency_code, checkout) {
      var deferred;
      deferred = $q.defer();
      deferred.resolve(JSON.stringify({
        amount: checkout.total_gross,
        currency: currency_code,
        country: checkout.billing_address.country_code,
        payment_method_flow: 'REDIRECT',
        payer: {
          name: checkout.billing_address.full_name,
          email: checkout.billing_address.email,
          phone: checkout.billing_address.phone,
          address: {
            city: checkout.billing_address.city,
            street: checkout.billing_address.street,
            number: checkout.billing_address.street_no,
            zip_code: checkout.billing_address.zip
          }
        },
        order_id: generateRandomString(),
        notification_url: checkout.confirm_dlocal_payment_url,
        callback_url: checkout.confirm_dlocal_payment_url,
        payment_intent_id: checkout.id
      }));
      return deferred.promise;
    }
  };
}).controller("paymentProcessorDlocalCtrl", function($log, $scope, $rootScope, $translate, configuration, paymentProcessorDlocalDefinition) {});
