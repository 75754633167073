angular

  .module "wundery.checkout"

  ####
  #### Confirmation
  ####

  .controller "ConfirmationCtrl", (
    $q
    $log
    $scope
    $stateParams
    checkouts
    amazonPayError
    loading
    integrations
  ) ->
    loadCheckout = ->
      loading.start "checkout:get"
      checkouts
        .get id: $stateParams.checkoutId, (checkout) ->
          $scope.checkout = checkout
        .$promise
        .then ->
          integrations.handle
            checkout: $scope.checkout
            scope: "confirmation"
        .finally ->
          loading.finish "checkout:get"

    replaceVariables = (text, variables) ->
      for key, value of variables
        text = text.replace(new RegExp("{{\\s*" + key + "\\s*}}", "g"), value)
      text

    $scope.load = ->
      loading.start "Loading checkout confirmation"
      loadCheckout()
        .then ->
          $scope.confirmationTitle = $scope.checkout.store.checkout_confirmation_title

          textVariables =
            customer_name: $scope.checkout.order.customer.full_name
            order_number: $scope.checkout.order.number
          $scope.confirmationText =
            replaceVariables($scope.checkout.store.checkout_confirmation_text, textVariables)

          footerVariables =
            store_url: $scope.checkout.store.url
            store_title: $scope.checkout.store.title
          $scope.confirmationFooter =
            replaceVariables($scope.checkout.store.checkout_confirmation_footer, footerVariables)

          $scope.confirmationEmailNotice =
            $scope.checkout.store.checkout_confirmation_email_notice
          $scope.confirmationDigitalEmailNotice =
            $scope.checkout.store.checkout_confirmation_digital_email_notice

    $scope.hasError = ->
      !_.isEmpty amazonPayError.getError()
    $scope.errorMsg = ->
      _.result amazonPayError.getError(), "message"

    $scope
      .load()
