angular

  .module "wundery.checkout"

  .factory "httpInterceptor", (
    $q
  ) ->

    responseError: (response) ->
      $q.reject response

    request: (config) ->
      config || $q.when config
