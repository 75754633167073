angular
  .module "wundery.checkout"
  .controller "authenticatePaypalExpressCtrl", (
    $scope
    $state
    paymentProcessorPaypalExpressDefinition
  ) ->
    initData = (checkout) ->
      if checkout
        payment_method = $scope.showPaymentPayPalExpress(checkout)
        paymentProcessorPaypalExpressDefinition
          .setupButton(payment_method.safe_settings, checkout, $scope, $state, true)
    initData($scope.checkout)
