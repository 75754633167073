angular

  .module "wundery.checkout"

  .factory "countries", (
    $translate
    translations
  ) ->

    all: ->
      _.map translations[$translate.use()]["COUNTRIES"], (title, value) ->
        title: title
        value: value



