angular.module("wundery.checkout").factory("paymentProcessorPaypalExpressDefinition", function($q, $log, $translate, checkouts, loading) {
  return {
    partial: false,
    setupButton: function(settings, checkout, scope, state, reauth) {
      var client_id, head, script;
      if (reauth == null) {
        reauth = false;
      }
      client_id = _.result(_.find(settings, {
        key: "client_id"
      }), "value");
      head = (document.getElementsByTagName("head"))[0];
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://www.paypal.com/sdk/js?client-id=" + client_id + "&disable-funding=credit,card,sepa,giropay,sofort,p24,venmo,bancontact,eps,ideal,mybank&currency=" + checkout.currency.code + "&commit=" + reauth;
      script.onload = function() {
        return paypal.Buttons({
          style: {
            color: reauth ? 'black' : 'gold',
            label: reauth ? 'buynow' : 'paypal'
          },
          onInit: function(data, actions) {
            var inputs;
            if (checkout.can_finish || state.current.name === 'checkout.cart') {
              actions.enable();
            } else {
              actions.disable();
            }
            inputs = document.querySelectorAll('input');
            return _.forEach(inputs, function(element) {
              return element.addEventListener('change', function() {
                return scope.activateHints().then(function() {
                  if (scope.checkout.can_finish) {
                    return actions.enable();
                  } else {
                    return actions.disable();
                  }
                });
              });
            });
          },
          onClick: function(data, actions) {
            loading.start("checkout.paypal_express");
            if (reauth) {
              scope.activateHints();
            }
            return checkouts.create_order({
              id: checkout.id,
              init_action: 'validate'
            }, function(response) {
              return console.log("passed the validation.");
            }).$promise["catch"](function(error) {
              loading.finish("checkout.paypal_express");
              scope.error = error.data;
              return actions.reject();
            });
          },
          createOrder: function(data, actions) {
            var paymentMethod;
            paymentMethod = scope.showPaymentPayPalExpress(checkout);
            if (reauth) {
              return checkouts.update_pp_shipping_costs({
                id: checkout.id
              }, {
                payment_method_id: paymentMethod.id
              }).$promise.then(function(response) {
                if (response.paypal_order_id) {
                  return response.paypal_order_id;
                }
              });
            } else {
              return checkouts.create_order({
                id: checkout.id
              }, {
                init_action: 'create',
                payment_method_id: paymentMethod.id
              }).$promise.then(function(response) {
                if (response.order) {
                  return response.order;
                } else {
                  scope.error = response.error;
                  return loading.finish("checkout.paypal_express");
                }
              })["catch"](function(error) {
                loading.finish("checkout.paypal_express");
                return scope.error = error.data;
              });
            }
          },
          onApprove: function(data, actions) {
            if (reauth) {
              return checkouts.approve_paypal_amount({
                id: checkout.id
              }).$promise.then(function(response) {
                return checkouts.finish({
                  id: checkout.id
                }, {}, function(response) {
                  return state.go("checkout.confirmation", {
                    checkoutId: checkout.id
                  }).then(function() {
                    return $window.location.reload();
                  });
                });
              });
            } else {
              return checkouts.approve_paypal_amount({
                id: checkout.id
              }).$promise.then(function(response) {
                return checkouts.update_shipping({
                  id: checkout.id
                }).$promise.then(function(response) {
                  loading.finish("checkout.paypal_express");
                  return actions.redirect(checkout.overview_url);
                });
              });
            }
          },
          onCancel: function(data, actions) {
            loading.finish("checkout.paypal_express");
            if (reauth) {
              return true;
            }
            if (data.orderID) {
              return checkouts.cancel_order({
                id: checkout.id
              }, function(response) {
                return console.log('cancel order');
              });
            }
          },
          onError: function(err) {
            console.log("error buttons" + err);
            return loading.finish("checkout.paypal_express");
          }
        }).render('#paypal-button-container');
      };
      return document.body.appendChild(script);
    }
  };
}).controller("paymentProcessorPaypalExpressCtrl", function($scope, $state, paymentProcessorPaypalExpressDefinition) {
  var initData;
  initData = function(checkout) {
    var payment_method;
    if (checkout) {
      payment_method = $scope.showPaymentPayPalExpress(checkout);
      return paymentProcessorPaypalExpressDefinition.setupButton(payment_method.safe_settings, checkout, $scope, $state);
    }
  };
  return initData($scope.checkout);
});
