angular.module("wundery.checkout").factory("payment_processors", function($q, $log, $injector) {
  return {
    defaultValidation: function(data) {
      $log.debug("Running default validation");
      return $q.when(true);
    },
    defaultAuthorization: function(data) {
      $log.debug("Running default authorization");
      return $q.when(true);
    },
    defaultSetup: function() {
      $log.debug("Running default setup");
      return $q.when(true);
    },
    definitionName: function(name) {
      var camelized, firstUppercased;
      camelized = _.camelCase(name);
      firstUppercased = camelized.charAt(0).toUpperCase() + camelized.slice(1);
      return "paymentProcessor" + firstUppercased + "Definition";
    },
    definition: function(name) {
      var definitionName;
      definitionName = this.definitionName(name);
      if ($injector.has(definitionName)) {
        return $injector.get(definitionName);
      } else {
        return {
          partial: false,
          validation: this.defaultValidation,
          authorization: this.defaultAuthorization
        };
      }
    },
    hasPartial: function(name) {
      return _.result(this.definition(name), "partial") === true;
    },
    partialPath: function(name) {
      return "app/plugins/payment_processors/" + name + "/templates/" + name + ".html";
    },
    paymentMethodUpdated: function(checkout) {
      var definition;
      if (!(_.has(checkout, 'payment_method.processor.name'))) {
        return;
      }
      definition = this.definition(checkout.payment_method.processor.name);
      if (_.has(definition, "select") && _.isFunction(definition.select)) {
        return definition.select(checkout);
      }
    },
    validation: function(name, data) {
      var definition, func;
      $log.debug("Requesting validation for '" + name + "'");
      definition = this.definition(name);
      if (_.has(definition, "validation")) {
        func = definition.validation;
        if (_.isFunction(func)) {
          return func(data);
        } else {
          return this.defaultValidation(data);
        }
      } else {
        return this.defaultValidation(data);
      }
    },
    authorization: function(name, data, settings, amount, currency_code, checkout) {
      var definition;
      $log.debug("Requesting authorization for '" + name + "'");
      definition = this.definition(name);
      if (_.has(definition, "authorization") && _.isFunction(definition.authorization)) {
        return definition.authorization(data, settings, amount, currency_code, checkout);
      } else {
        return this.defaultAuthorization(data);
      }
    },
    setup: function(name, settings, test_mode, checkout) {
      var definition, func;
      $log.debug("Requesting setup for '" + name + "' (test_mode = " + test_mode + ")");
      definition = this.definition(name);
      if (_.has(definition, "setup")) {
        func = definition.setup;
        if (_.isFunction(func)) {
          return func(settings, test_mode, checkout);
        } else {
          return this.defaultSetup();
        }
      } else {
        return this.defaultSetup();
      }
    }
  };
});
