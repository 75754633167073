angular.module("wundery.checkout").directive("numbersOnly", function() {
  return {
    require: "?ngModel",
    link: function(scope, element, attrs, ngModelCtrl) {
      if (!ngModelCtrl) {
        return;
      }
      ngModelCtrl.$parsers.push(function(val) {
        var val;
        var clean;
        if (angular.isUndefined(val)) {
          val = "";
        }
        clean = val.replace(/[^0-9]+/g, "");
        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });
      element.bind("keypress", function(event) {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
      });
    }
  };
});
