angular

  .module "wundery.checkout"

  ####
  #### Cart
  ####

  .controller "CartCtrl", (
    $q
    $log
    $scope
    $state
    $stateParams
    checkout_items
    countries
    checkouts
    helpers
    loading
    integrations
    payment_processors
  ) ->

    loadCheckout = ->
      loading.start "checkout"

      checkouts
        .get id: $stateParams.checkoutId, (checkout) ->
          $scope.checkout = checkout
        .$promise
        .then ->
          integrations.handle
            checkout: $scope.checkout
            scope: "cart"
        .catch (error) ->
          $log.debug "Failed integration: #{error.message}"
        .finally ->
          loading.finish "checkout"

    loadShippingSuggestion = (checkout, country_code) ->
      loading.start "suggest:shipping"
      checkouts
        .suggest_shipping
          id: checkout.id
          country_code: country_code, (suggestion) ->
            $scope.availability = _.get suggestion, "shipping_method_availability"
            if _.isObject $scope.availability
              $log.debug "API had a shipping suggestion for '#{country_code}'",
                $scope.availability
            else
              $log.debug "API had no shipping suggestion for '#{country_code}'"
        .$promise
        .finally ->
          loading.finish "suggest:shipping"

    load = ->
      $q.when true

    load()
      .then ->
        $scope.quantity_errors = {}

      .then ->
        loadCheckout()
      .then ->
        possibleCodes = _.map $scope.checkout.store.countries, (country) ->
          String(country.code).toUpperCase()

        possibleCountries = _.filter countries.all(), (country) ->
          country.value in possibleCodes

        storeCode = _.get $scope.checkout, 'store.address.country_code'

        $scope.country_code = if storeCode in possibleCodes
          storeCode
        else
          _.first possibleCodes
        $scope.countries = possibleCountries

        $scope.shippable_countries = helpers.shippableCountries($scope.checkout)

      .then ->
        loadShippingSuggestion $scope.checkout, $scope.country_code

    $scope.remove = (checkout_item) ->
      checkout_items
        .delete id: checkout_item.id
        .$promise
        .then ->
          $state.go $state.current, null, reload: true

    $scope.updateQuantity = (checkout_item, new_quantity) ->
      $scope.quantity_errors[checkout_item.id] = false
      if _.isFinite _.parseInt new_quantity
        checkout_items
          .update id: checkout_item.id, quantity: new_quantity, (checkout_item) ->
            $state.go $state.current, null, reload: true
          .$promise
          .catch (error) ->
            key = _.get error, "data.type", null
            if key == "inventory"
              $scope.quantity_errors[checkout_item.id] = true

    $scope.updateCountry = (checkout, country_code) ->
      loadShippingSuggestion checkout, country_code

    $scope.showPaymentPayPalExpress = (checkout) ->
      return null unless checkout
      _.first(
        _.filter(
          checkout.payment_methods,
          {processor: {name: 'paypal_express'}}
        )
      )

    $scope.partialPath = (name) ->
      payment_processors.partialPath name