
angular

  .module "wundery.checkout"

  .directive "wcDownload", ->
    restrict: "A"
    link: (scope, element, attr, ctrl) ->
      element.on('click', (event) ->
        event.preventDefault()
        saveAs(this.href, this.href.slice(this.href.lastIndexOf('/') + 1))
      )
