angular.module("wundery.checkout").controller("ConfirmationCtrl", function($q, $log, $scope, $stateParams, checkouts, amazonPayError, loading, integrations) {
  var loadCheckout, replaceVariables;
  loadCheckout = function() {
    loading.start("checkout:get");
    return checkouts.get({
      id: $stateParams.checkoutId
    }, function(checkout) {
      return $scope.checkout = checkout;
    }).$promise.then(function() {
      return integrations.handle({
        checkout: $scope.checkout,
        scope: "confirmation"
      });
    })["finally"](function() {
      return loading.finish("checkout:get");
    });
  };
  replaceVariables = function(text, variables) {
    var key, value;
    for (key in variables) {
      value = variables[key];
      text = text.replace(new RegExp("{{\\s*" + key + "\\s*}}", "g"), value);
    }
    return text;
  };
  $scope.load = function() {
    loading.start("Loading checkout confirmation");
    return loadCheckout().then(function() {
      var footerVariables, textVariables;
      $scope.confirmationTitle = $scope.checkout.store.checkout_confirmation_title;
      textVariables = {
        customer_name: $scope.checkout.order.customer.full_name,
        order_number: $scope.checkout.order.number
      };
      $scope.confirmationText = replaceVariables($scope.checkout.store.checkout_confirmation_text, textVariables);
      footerVariables = {
        store_url: $scope.checkout.store.url,
        store_title: $scope.checkout.store.title
      };
      $scope.confirmationFooter = replaceVariables($scope.checkout.store.checkout_confirmation_footer, footerVariables);
      $scope.confirmationEmailNotice = $scope.checkout.store.checkout_confirmation_email_notice;
      return $scope.confirmationDigitalEmailNotice = $scope.checkout.store.checkout_confirmation_digital_email_notice;
    });
  };
  $scope.hasError = function() {
    return !_.isEmpty(amazonPayError.getError());
  };
  $scope.errorMsg = function() {
    return _.result(amazonPayError.getError(), "message");
  };
  return $scope.load();
});
