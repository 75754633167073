angular.module("wundery.checkout").factory("validation", function() {
  return {
    isEmail: function(string) {
      var re;
      re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(string);
    },
    isCreditcard: function(value) {
      var bEven, cDigit, n, nCheck, nDigit;
      if (/[^0-9-\s]+/.test(value)) {
        return false;
      }
      nCheck = 0;
      nDigit = 0;
      bEven = false;
      value = value.replace(/\D/g, '');
      n = value.length - 1;
      while (n >= 0) {
        cDigit = value.charAt(n);
        nDigit = parseInt(cDigit, 10);
        if (bEven) {
          if ((nDigit *= 2) > 9) {
            nDigit -= 9;
          }
        }
        nCheck += nDigit;
        bEven = !bEven;
        n--;
      }
      return nCheck % 10 === 0;
    }
  };
});
