angular.module("wundery.checkout").factory("paymentProcessorStripeConnectDefinition", function($log, $q, $rootScope, $translate, configuration, checkouts, loading) {
  return {
    getStripeJSURL: function() {
      return configuration.read('stripe_js_url');
    },
    isStripeJSInjected: function() {
      return $("script[src='" + (this.getStripeJSURL()) + "']").length > 0;
    },
    getIntentToken: function(checkout) {
      var deferred;
      deferred = $q.defer();
      loading.start("checkout:update");
      checkouts.create_or_update_payment_intent(checkout, function(_checkout) {
        $rootScope.checkout = _checkout;
        return deferred.resolve(_checkout.payment_intent_token);
      }).$promise["finally"](function() {
        return loading.finish("checkout:update");
      });
      return deferred.promise;
    },
    injectStripe: function(checkout) {
      var head, script;
      if (this.isStripeJSInjected()) {
        $log.info('Stripe.js already injected');
        return;
      }
      $log.info('inject Stripe.js');
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = this.getStripeJSURL();
      script.onload = (function(_this) {
        return function() {
          var publicKey, settings;
          settings = checkout.payment_method.safe_settings;
          publicKey = _.result(_.find(settings, {
            key: "public_key"
          }), "value");
          if (checkout.use_stripe_payment_element) {
            return _this.getIntentToken(checkout).then(function(payment_intent_token) {
              var stripeUserID;
              $log.info("retrieved intent '" + payment_intent_token + "'");
              stripeUserID = _.result(_.find(settings, {
                key: "stripe_user_id"
              }), "value");
              document.querySelector("#stripe-payment-element-form-" + checkout.payment_method.id).addEventListener("submit", function() {
                return alert("submit");
              });
              $rootScope.stripeInstance = Stripe(publicKey, {
                stripeAccount: stripeUserID
              });
              $rootScope.stripeElementsInstance = $rootScope.stripeInstance.elements({
                locale: $translate.use(),
                clientSecret: payment_intent_token,
                appearance: {
                  theme: 'stripe'
                }
              });
              $rootScope.stripePaymentElement = $rootScope.stripeElementsInstance.create("payment");
              return $rootScope.stripePaymentElement.mount("#stripe-payment-element-" + checkout.payment_method.id);
            });
          } else {
            $rootScope.stripeInstance = Stripe(publicKey);
            $rootScope.stripeElements = $rootScope.stripeInstance.elements({
              locale: $translate.use()
            });
            $rootScope.stripeCard = $rootScope.stripeElements.create('card', {
              hidePostalCode: true
            });
            return $rootScope.stripeCard.mount("#card-element-" + checkout.payment_method.id);
          }
        };
      })(this);
      head = (document.getElementsByTagName("head"))[0];
      return head.appendChild(script);
    },
    partial: true,
    authorization: function(card, settings, amount, currency_code, checkout) {
      var deferred;
      deferred = $q.defer();
      if (checkout.use_stripe_payment_element) {
        this.getIntentToken(checkout).then(function() {
          var options;
          $log.info("requesting stripe authorization for checkout");
          options = {
            elements: $rootScope.stripeElementsInstance,
            redirect: 'if_required',
            confirmParams: {
              return_url: checkout.confirm_url
            }
          };
          return $rootScope.stripeInstance.confirmPayment(options).then(function(result) {
            if (_.has(result, "error")) {
              $log.info("stripe returned an error: " + (JSON.stringify(result.error)));
              return deferred.reject([
                {
                  message: result.error.message
                }
              ]);
            } else {
              $log.info("successfully confirmed payment");
              return deferred.resolve({
                payment_intent_id: result.paymentIntent.id
              });
            }
          });
        });
      } else {
        $rootScope.stripeInstance.createPaymentMethod('card', $rootScope.stripeCard).then(function(result) {
          if (_.has(result, "error")) {
            return deferred.reject([
              {
                message: result.error.message
              }
            ]);
          } else {
            return deferred.resolve({
              payment_method_id: result.paymentMethod.id
            });
          }
        });
      }
      return deferred.promise;
    },
    validation: function(card) {
      var deferred;
      deferred = $q.defer();
      if ($rootScope.stripeError) {
        deferred.reject([
          {
            message: $rootScope.stripeError.message
          }
        ]);
      } else {
        deferred.resolve(true);
      }
      return deferred.promise;
    },
    select: function(checkout) {
      return this.injectStripe(checkout);
    }
  };
}).controller("paymentProcessorStripeConnectCtrl", function($log, $scope, $rootScope, $translate, configuration, paymentProcessorStripeConnectDefinition) {
  if ($scope.checkout.payment_method && $scope.checkout.payment_method.processor.name === "stripe_connect") {
    return paymentProcessorStripeConnectDefinition.select($scope.checkout);
  }
});
