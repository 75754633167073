angular

  .module "wundery.checkout"

  .factory "paymentProcessorRazorpayDefinition", (
    $log
    $q
    $rootScope
    $translate
    configuration
    checkouts
    loading
  ) ->
    partial: true
    getJSURL: -> "https://checkout.razorpay.com/v1/checkout.js"
    
    isJSInjected: -> $("script[src='#{@getJSURL()}']").length > 0 

    getIntentToken: (checkout) ->
      deferred = $q.defer()
      loading.start "checkout:update"  
      checkouts
        .create_or_update_payment_intent checkout, (_checkout) ->
          $rootScope.checkout = _checkout
          deferred.resolve _checkout.payment_intent_token
        .$promise
        .catch (err) ->
          deferred.reject 'Unable to retrieve payment intent'
        .finally ->
          loading.finish "checkout:update"
      deferred.promise

    injectJS: (checkout) ->
      if @isJSInjected()
        $log.info 'razorpay.js already injected'
        return

      $log.info 'inject razorpay.js'

      script = document.createElement "script"
      script.type = "text/javascript"
      script.src = @getJSURL()
      head = (document.getElementsByTagName "head")[0]            
      head.appendChild script

    # invoked by the checkout framework
    authorization: (card, settings, amount, currency_code, checkout) ->
      deferred = $q.defer()
      
      if typeof Razorpay == 'undefined'
        deferred.reject [{ message: "Razorpay is not available" }]
      else
        @getIntentToken(checkout)
          .then (payment_intent_token) ->

            keyId = _.result _.find(settings, key: "key_id"), "value"

            options =
              key: keyId
              amount: amount
              currency: currency_code
              name: checkout.store.title
              order_id: payment_intent_token
              prefill: 
                email: checkout.billing_address.email
                contact: checkout.billing_address.phone
              handler: (response) ->
                # pass the entire response as authorization to the backend
                # in order to be verifyable
                # see: https://razorpay.com/docs/payments/payment-gateway/web-integration/standard/build-integration/#generate-signature-on-your-server
                deferred.resolve(JSON.stringify(response))
              modal:
                ondismiss: ->
                  deferred.reject 'ABORT'      

            razorpayInstance = new Razorpay options
            razorpayInstance.on 'payment.failed', (response) -> 
              message = "#{response.error.description} (#{response.error.code})"
              deferred.reject [{ message: message }]
            razorpayInstance.open()    
          .catch (err) ->
            deferred.reject [{ message: err }]
    
      deferred.promise

    # invoked by the checkout framework
    validation: (card) ->
      deferred = $q.defer()

      deferred.resolve true

      deferred.promise

    # invoked by the checkout framework when a 
    # payment method using this processor is selected
    select: (checkout) -> 
      @injectJS checkout

  .controller "paymentProcessorRazorpayCtrl", (
    $log
    $scope
    $rootScope
    $translate
    configuration
    paymentProcessorRazorpayDefinition
  ) ->
    if $scope.checkout.payment_method && $scope.checkout.payment_method.processor.name == "razorpay"
      paymentProcessorRazorpayDefinition.select $scope.checkout
