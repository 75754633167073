angular.module("wundery.checkout").factory("amazonPayError", function($rootScope) {
  $rootScope.error = {};
  return {
    addError: function(error) {
      return $rootScope.error = error;
    },
    getError: function() {
      return $rootScope.error;
    },
    clearError: function() {
      return $rootScope.error = {};
    }
  };
});
