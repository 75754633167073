angular

  .module "wundery.checkout"

  .factory "configuration", (
    $log
    environment
    environmentName
    translations
  ) ->

    isDevelopment: ->
      environmentName == "development"

    isProduction: ->
      environmentName == 'production'

    read: (key) ->
      result = _.result(environment, key)
      if _.isUndefined result
        $log.error "Cannot read '#{key}' from envirnment"
      else
        result

    locale: (locale) ->
      if locale in _.keys(translations)
        locale
      else
        "en"


