angular.module("wundery.checkout").factory("paymentProcessorAmazonPayDefinition", function($q, $log, checkouts, $modal, $state, $timeout, finishAmazonCheckout, configuration) {
  return {
    partial: true,
    setup: function(settings, test_mode, checkout) {
      var amazonPayWidgetTemplate, client_id, disableAmazonBuyBtn, head, logoutAmazon, merchant_id, region, resetAmazonBuyBtn, script, showAddressBookWidget, showErrorModal, showWalletWidget, src, src_path;
      merchant_id = _.result(_.find(settings, {
        key: "merchant_id"
      }), "value");
      client_id = _.result(_.find(settings, {
        key: "client_id"
      }), "value");
      region = _.result(_.find(settings, {
        key: "region"
      }), "value");
      if (!region) {
        region = "de";
      }
      src_path = "amazon_pay_urls";
      if (configuration.isProduction() && test_mode) {
        src_path = "test_mode.amazon_pay_urls";
      }
      src = configuration.read("" + src_path + "." + region);
      $log.info("Injecting amazon pay library (test_mode = " + test_mode + ")");
      head = (document.getElementsByTagName("head"))[0];
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      head.appendChild(script);
      window.onAmazonLoginReady = function() {
        return amazon.Login.setClientId(client_id);
      };
      window.onAmazonPaymentsReady = function() {
        var authRequest, params;
        $log.info('Injecting Amazon Pay button (onAmazonPaymentsReady invoked)');
        authRequest = void 0;
        params = {
          type: 'PwA',
          authorization: function() {
            var loginOptions;
            loginOptions = {
              scope: "profile payments:widget payments:shipping_address",
              popup: true
            };
            return authRequest = amazon.Login.authorize(loginOptions, function(t) {
              Cookies.set('amazonAccessToken', t.access_token);
              $modal.open({
                template: amazonPayWidgetTemplate(),
                backdropClass: 'amazon-backdrop',
                controller: function($scope, $modalInstance) {
                  $scope.buy = function() {
                    $modalInstance.close();
                    $state.go("checkout.confirmation", {
                      checkoutId: checkout.id
                    });
                    return finishAmazonCheckout.finish(checkout).$promise.then(function() {
                      return logoutAmazon();
                    });
                  };
                  return $modalInstance.result["finally"] = function() {
                    return resetAmazonBuyBtn();
                  };
                }
              });
              return showAddressBookWidget();
            });
          },
          onError: function(error) {
            return $log.debug("ERROR: Code = " + (error.getErrorCode()) + "; Message = " + (error.getErrorMessage()));
          }
        };
        window.OffAmazonPayments.Button("amazon-pay-button", merchant_id, params);
        return showAddressBookWidget();
      };
      showAddressBookWidget = function() {
        return new OffAmazonPayments.Widgets.AddressBook({
          sellerId: merchant_id,
          onReady: function(orderReference) {
            var orderReferenceId;
            disableAmazonBuyBtn();
            orderReferenceId = orderReference.getAmazonOrderReferenceId();
            Cookies.set('amazonOrderReferenceId', orderReferenceId);
            checkouts.create_amazon_shipping({
              id: checkout.id
            }, {
              amazon_order_reference_id: orderReferenceId,
              amazon_access_token: Cookies.get('amazonAccessToken')
            }).$promise.then(function(response) {
              return resetAmazonBuyBtn();
            });
            return showWalletWidget(orderReferenceId);
          },
          onOrderReferenceCreate: function(orderReference) {},
          onAddressSelect: function(t) {},
          design: {
            designMode: 'responsive'
          },
          onError: function(error) {
            $log.info("ERROR: Code = " + (error.getErrorCode()) + "; Message = " + (error.getErrorMessage()));
            return showErrorModal(error.getErrorMessage());
          }
        }).bind('amazon-pay-address-book');
      };
      showWalletWidget = function(orderReferenceId) {
        return new OffAmazonPayments.Widgets.Wallet({
          sellerId: merchant_id,
          amazonOrderReferenceId: orderReferenceId,
          onReady: function(orderReference) {},
          onPaymentSelect: function(orderReference) {},
          design: {
            designMode: 'responsive'
          },
          onError: function(error) {
            return $log.debug("ERROR: Code = " + (error.getErrorCode()) + "; Message = " + (error.getErrorMessage()));
          }
        }).bind('amazon-pay-wallet');
      };
      amazonPayWidgetTemplate = function() {
        return "<div class='modal-body'> <div id='amazon-pay-address-book' class='show-amazon-pay-wallet'></div> <div id='amazon-pay-wallet' class='show-amazon-pay-wallet'></div> </div> <div class='modal-footer wy-text-left'> <button type='button' id='amazon-buy-btn' class='wy-button wy-button-success wy-button-block wy-button-large margin-bottom-half' ng-click='buy()'>Buy Now</button> <div id='amazon-error' class='label label-danger space-default'></div> </div>";
      };
      logoutAmazon = function() {
        Cookies.set('amazonAccessToken', '');
        return amazon.Login.logout();
      };
      resetAmazonBuyBtn = function() {
        return $('#amazon-buy-btn').prop('disabled', false);
      };
      disableAmazonBuyBtn = function() {
        return $('#amazon-buy-btn').prop('disabled', true);
      };
      return showErrorModal = function(errorMsg) {
        disableAmazonBuyBtn();
        $('#amazon-pay-wallet').removeClass('show-amazon-pay-wallet').addClass('hide-amazon-pay-wallet');
        return $('#amazon-error').text(errorMsg);
      };
    },
    authorization: function(card, settings) {
      return $q.when(true);
    },
    validation: function(card) {
      return $q.when(true);
    }
  };
}).controller("paymentProcessorAmazonPayCtrl", function($scope, creditcard) {});
