angular

  .module "wundery.checkout"

  .factory "paymentProcessorStripeConnectDefinition", (
    $log
    $q
    $rootScope
    $translate
    configuration
    checkouts
    loading
  ) ->
    getStripeJSURL: -> configuration.read 'stripe_js_url'
    
    isStripeJSInjected: -> $("script[src='#{@getStripeJSURL()}']").length > 0 

    getIntentToken: (checkout) ->
      deferred = $q.defer()
      loading.start "checkout:update"  
      checkouts
        .create_or_update_payment_intent checkout, (_checkout) ->
          $rootScope.checkout = _checkout
          deferred.resolve _checkout.payment_intent_token
        .$promise
        .finally ->
          loading.finish "checkout:update"
      deferred.promise

    injectStripe: (checkout) ->
      if @isStripeJSInjected()
        $log.info 'Stripe.js already injected'
        return

      $log.info 'inject Stripe.js'

      script = document.createElement "script"
      script.type = "text/javascript"
      script.src = @getStripeJSURL()

      # Callback which is run when Stripe was loaded. 
      # This is the place to convert elements on the page to payment forms
      script.onload = =>
        settings = checkout.payment_method.safe_settings
        publicKey = _.result _.find(settings, key: "public_key"), "value"

        # TODO(stripe): temporary store-level feature flag to roll this out
        # for selected production stores only; remove after full rollout.
        # Controlled via field on store record.
        if checkout.use_stripe_payment_element

          # Retrieve a new (or existing) payment intent token
          @getIntentToken(checkout).then (payment_intent_token) -> 
            $log.info "retrieved intent '#{payment_intent_token}'"

            # Required for the connect flow to work
            stripeUserID = _.result _.find(settings, key: "stripe_user_id"), "value"

            document
              .querySelector "#stripe-payment-element-form-#{checkout.payment_method.id}"
              .addEventListener "submit", -> alert "submit"

            $rootScope.stripeInstance = Stripe publicKey, stripeAccount: stripeUserID
            $rootScope.stripeElementsInstance = $rootScope.stripeInstance.elements
              locale: $translate.use()
              clientSecret: payment_intent_token
              appearance: 
                theme: 'stripe'

            $rootScope.stripePaymentElement = $rootScope.stripeElementsInstance.create "payment"
            $rootScope.stripePaymentElement.mount "#stripe-payment-element-#{checkout.payment_method.id}"

        # TODO(stripe): this is the old card form; remove after full rollout    
        else
          # "remember" the Stripe instance because it is needed at other places
          $rootScope.stripeInstance = Stripe publicKey
          $rootScope.stripeElements = $rootScope.stripeInstance.elements locale: $translate.use()
          $rootScope.stripeCard = $rootScope.stripeElements.create('card', { hidePostalCode : true })
          $rootScope.stripeCard.mount "#card-element-#{checkout.payment_method.id}"
      
      head = (document.getElementsByTagName "head")[0]            
      head.appendChild script

    # used by the checkout framework to include a partial 
    # which contains the processor-sepcific UI elements
    partial: true

    # invoked by the checkout framework
    authorization: (card, settings, amount, currency_code, checkout) ->
      deferred = $q.defer()

      # TODO(stripe): temporary store-level feature flag to roll this out
      # for selected production stores only; remove after full rollout.
      # Controlled via field on store record.
      if checkout.use_stripe_payment_element
        @getIntentToken(checkout).then -> 
          $log.info "requesting stripe authorization for checkout"

          # see: https://stripe.com/docs/js/payment_intents/confirm_payment#confirm_payment_intent-options
          options =
            elements: $rootScope.stripeElementsInstance
            redirect: 'if_required'
            confirmParams:
              return_url: checkout.confirm_url

          $rootScope.stripeInstance.confirmPayment(options).then (result) ->
            if _.has result, "error"
              $log.info "stripe returned an error: #{JSON.stringify(result.error)}"
              deferred.reject [{message: result.error.message}]
            else
              $log.info "successfully confirmed payment"
              deferred.resolve payment_intent_id: result.paymentIntent.id
        
      # TODO(stripe): this is the old card form; remove after full rollout
      else
        $rootScope.stripeInstance.createPaymentMethod('card', $rootScope.stripeCard).then (result) ->
          if _.has result, "error"
            deferred.reject [{message: result.error.message}]
          else
            deferred.resolve payment_method_id: result.paymentMethod.id

      deferred.promise

    # invoked by the checkout framework
    validation: (card) ->
      deferred = $q.defer()
      if $rootScope.stripeError
        deferred.reject [{message: $rootScope.stripeError.message}]
      else
        deferred.resolve true

      deferred.promise

    # invoked by the checkout framework when a 
    # payment method using this processor is selected
    select: (checkout) -> 
      @injectStripe checkout

  .controller "paymentProcessorStripeConnectCtrl", (
    $log
    $scope
    $rootScope
    $translate
    configuration
    paymentProcessorStripeConnectDefinition
  ) ->
    if $scope.checkout.payment_method && $scope.checkout.payment_method.processor.name == "stripe_connect"
      paymentProcessorStripeConnectDefinition.select $scope.checkout
