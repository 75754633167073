angular.module("wundery.checkout").config(function($translateProvider, translations) {

  /*
  Register translations
   */
  _.forIn(translations, function(translation, locale) {
    return $translateProvider.translations(locale, translation);
  });
  return $translateProvider.useMissingTranslationHandler("missingTranslationHandler");
}).run(function($translate, $log, configuration, client) {

  /*
  Set locale to use
   */
  $translate.fallbackLanguage("en");
  $translate.use(configuration.locale(client.locale()));
  return $log.debug("Selected translation '" + ($translate.use()) + "'");
});
