angular.module("wundery.checkout").factory("paymentProcessorTwoCheckoutDefinition", function($log, $q, $rootScope, $translate, configuration, checkouts, loading) {
  return {
    partial: true,
    getJSURL: function() {
      return "https://secure.2checkout.com/checkout/client/twoCoInlineCart.js";
    },
    isJSInjected: function() {
      return $("script[src='" + (this.getJSURL()) + "']").length > 0;
    },
    injectJS: function(checkout) {
      var head, script;
      if (this.isJSInjected()) {
        $log.info('2co already injected');
        return;
      }
      $log.info('inject 2co');
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = this.getJSURL();
      script.async = true;
      script.onload = function() {
        var config;
        if (window.TwoCoInlineCart) {
          config = {
            cart: {
              host: "https://secure.2checkout.com"
            }
          };
          _.forEach(_.keys(config), function(key) {
            return window.TwoCoInlineCart.setup.setConfig(key, config[key]);
          });
          return window.TwoCoInlineCart.register();
        }
      };
      head = (document.getElementsByTagName("head"))[0];
      return head.appendChild(script);
    },
    authorization: function(card, settings, amount, currency_code, checkout) {
      var deferred, isTestMode, merchantCode;
      deferred = $q.defer();
      merchantCode = _.result(_.find(settings, {
        key: "merchant_code"
      }), "value");
      TwoCoInlineCart.setup.setMerchant(merchantCode);
      TwoCoInlineCart.cart.setReset(true);
      TwoCoInlineCart.setup.setMode('DYNAMIC');
      TwoCoInlineCart.cart.setCurrency(currency_code);
      TwoCoInlineCart.cart.setCartLockedFlag(true);
      TwoCoInlineCart.cart.setLanguage($translate.use());
      TwoCoInlineCart.cart.setAutoAdvance(true);
      TwoCoInlineCart.billing.setName(checkout.billing_address.full_name);
      TwoCoInlineCart.billing.setCountry(checkout.billing_address.country_code);
      TwoCoInlineCart.billing.setEmail(checkout.billing_address.email);
      TwoCoInlineCart.cart.setReturnMethod({
        type: 'redirect',
        url: checkout.confirm_url
      });
      isTestMode = _.result(checkout.payment_method, "test_mode");
      if (isTestMode) {
        $log.info("using test mode");
        TwoCoInlineCart.cart.setTest(true);
      }
      TwoCoInlineCart.events.subscribe('cart:closed', function() {
        return deferred.reject('ABORT');
      });
      TwoCoInlineCart.products.add({
        name: 'Cart items',
        quantity: 1,
        price: checkout.total_gross
      });
      TwoCoInlineCart.cart.checkout();
      return deferred.promise;
    },
    validation: function(card) {
      var deferred;
      deferred = $q.defer();
      deferred.resolve(true);
      return deferred.promise;
    },
    select: function(checkout) {
      return this.injectJS(checkout);
    }
  };
}).controller("paymentProcessorTwoCheckoutCtrl", function($log, $scope, $rootScope, $translate, configuration, paymentProcessorTwoCheckoutDefinition) {
  if ($scope.checkout.payment_method && $scope.checkout.payment_method.processor.name === "two_checkout") {
    return paymentProcessorTwoCheckoutDefinition.select($scope.checkout);
  }
});
