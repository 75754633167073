angular

  .module "wundery.checkout"

  .factory "api", (
    configuration
  ) ->

    base: ->
      configuration.read "api_url"

    url: (path) ->
      "#{this.base()}/storefront_api/v1/#{path}"

